import axios from 'axios';
import { getApiUrl } from '../utils/env';

function onSuccess(response: any) {
  return response.data.data;
}

function onError(error: any) {
  return Promise.reject(error);
}

export function initAxios() {
  axios.defaults.baseURL = getApiUrl();
  axios.interceptors.response.use(onSuccess, onError);
}
