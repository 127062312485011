import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as WalletIcon } from '../../../assets/img/wallet.svg';
import { formatShortAddress } from '../../../utils/format';
import { LargeHeading, StyledButton } from '../common';
import { AccountTab, mainState, openConnectModal, setAccountTab } from '../slice';
import { Switch } from '../Switch';
import { responsive } from '../theme';
import { AccountInfo } from './AccountInfo';

const LargeHeadingWithMargins = styled(LargeHeading)({
  margin: '2rem 0 0 0',
  [responsive.md]: {
    fontSize: '32px',
    margin: '3rem auto',
  },
});

const SeparationLine = styled.div(({ theme }) => ({
  height: '1px',
  flexBasis: 0,
  flexGrow: 1,
  background: theme.border.secondary,
  width: 'calc(100% + 3rem)',
}));

const SwitchContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  [`${SeparationLine}:nth-child(1)`]: {
    marginLeft: '-3rem',
  },
  [`${SeparationLine}:nth-child(3)`]: {
    marginRight: '-3rem',
  },
});

const StyledWalletIcon = styled(WalletIcon)({
  marginLeft: '1em',
  marginTop: '-3px',
});

const NetworkName = styled.div(({ theme }) => ({
  fontSize: '16px',
  textTransform: 'uppercase',
  color: theme.text.secondary,
  fontWeight: 500,
  marginRight: '2em',
}));

const Wallet = styled.div({
  display: 'flex',
  alignItems: 'center',
  fill: 'currentcolor',
  stroke: 'currentcolor',
  margin: '0 auto',
  [responsive.sm]: {
    flexDirection: 'column',
    [`${NetworkName}`]: {
      marginRight: 0,
      marginBottom: '1rem',
    },
  },
});

const RegisterValidatorLink = styled.a({
  display: 'flex',
  textDecoration: 'none',
  paddingBottom: '10px',
  '& > .MuiButtonBase-root': {
    flex: 1,
  },
});

const WalletHeader = styled.div({
  marginBottom: '2.5rem',
  display: 'flex',
  flexDirection: 'column',
  [responsive.md]: {
    width: '360px',
    margin: '0 auto 2rem auto',
    [`${LargeHeadingWithMargins}`]: {
      order: 1,
    },
    [`${RegisterValidatorLink}`]: {
      order: 2,
    },
    [`${StyledButton}`]: {
      order: 2,
    },
    [`${Wallet}`]: {
      order: 2,
    },
  },
});

const accountTabs = ['Dashboard', 'Activity'];

interface AccountProps {
  styled?: boolean;
}

export const Account = ({ styled }: AccountProps) => {
  const { userWallet, accountTab } = useSelector(mainState);
  const dispatch = useDispatch();

  const handleConnect = useCallback(() => {
    dispatch(openConnectModal());
  }, [dispatch]);

  const handleAccountTabChange = useCallback(
    (key: number) => {
      dispatch(setAccountTab({ tab: accountTabs[key] as AccountTab }));
    },
    [dispatch],
  );

  return (
    <>
      <WalletHeader>
        {userWallet ? (
          <Wallet>
            <NetworkName>{userWallet.networkName}</NetworkName>
            <StyledButton onClick={handleConnect} $grow>
              {formatShortAddress(userWallet.address, 10, 4)}
              <StyledWalletIcon />
            </StyledButton>
          </Wallet>
        ) : (
          <StyledButton onClick={handleConnect}>Connect your wallet</StyledButton>
        )}
        <LargeHeadingWithMargins>Your Account</LargeHeadingWithMargins>
      </WalletHeader>
      {styled ? (
        <SwitchContainer>
          <SeparationLine />
          <Switch
            values={accountTabs}
            onChange={handleAccountTabChange}
            activeKey={accountTabs.indexOf(accountTab)}
            tabWidth="180px"
            noResponsive
          />
          <SeparationLine />
        </SwitchContainer>
      ) : (
        <Switch values={accountTabs} onChange={handleAccountTabChange} activeKey={accountTabs.indexOf(accountTab)} />
      )}
      <AccountInfo styled={styled} />
    </>
  );
};
