import styled from 'styled-components';
import { ReactComponent as FilledStake } from '../../../assets/img/stake-logo-filled.svg';
import { ReactComponent as Unstake } from '../../../assets/img/stake-logo-unstake.svg';
import { ReactComponent as Stake } from '../../../assets/img/stake-logo.svg';

export const FilledStakeImage = styled(FilledStake)({
  marginBottom: '2rem',
});

export const StakeImage = styled(Stake)({
  marginBottom: '2rem',
});

export const ConfirmationBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '2rem',
});

export const UnstakeImage = styled(Unstake)({
  marginBottom: '2rem',
});
