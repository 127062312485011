import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KycModal } from '../main/KycModal';
import { closeKycModal, kycValidate, nominatorState } from './slice';

export function NominatorKycModal() {
  const dispatch = useDispatch();
  const { kycModal } = useSelector(nominatorState);

  const handleClose = useCallback(() => {
    dispatch(closeKycModal());
  }, [dispatch]);

  const handleValidate = useCallback(() => {
    dispatch(kycValidate());
  }, [dispatch]);

  if (!kycModal) {
    return null;
  }

  const { step, error, link } = kycModal;
  return <KycModal step={step} error={error} link={link} onClose={handleClose} onValidate={handleValidate} />;
}
