import React from 'react';
import styled from 'styled-components';
import { getNominatorVars, getValidatorVars } from '../../../../utils/env';
import { LargeHeading } from '../../common';
import { CopyAddress } from '../../CopyAddress';
import { responsive } from '../../theme';

const noiaTokenAddress = '0xa8c8CfB141A3bB59FEA1E2ea6B79b5ECBCD7b6ca';

const AddressList = styled.div({});

const AddressItem = styled.div({
  marginBottom: '0.5rem',
  '& > h3': {
    fontSize: '18px',
    margin: 0,
    fontWeight: 400,
  },
  '& > div': {
    display: 'flex',
    '& img': {
      marginLeft: '0.5rem',
    },
  },
  [responsive.md]: {
    '& > h3': {
      fontSize: '14px',
    },
  },
});

const ContractAddresses = styled.div(({ theme }) => ({
  padding: '3rem 0',
  borderTop: `1px solid ${theme.border.secondary}`,
  display: 'flex',
  flexDirection: 'column',
  '& > h2': {
    marginRight: 'auto',
  },
  [responsive.md]: {
    alignItems: 'center',
    padding: '3rem 0 1.5rem 0',
  },
}));

const AddressLink = styled.a(({ theme }) => ({
  color: theme.text.link,
  textUnderlineOffset: '2px',
  fontSize: '24px',
  display: 'flex',
  alignItems: 'center',
  '& img': {
    marginLeft: '1rem',
  },
  [responsive.sm]: {
    fontSize: '18px',
  },
  '&:hover': {
    opacity: 0.5,
  },
}));

const SmallAddressLink = styled(AddressLink)({
  fontSize: '21px',
  [responsive.md]: {
    fontSize: '14px',
  },
});

const AddressesWarning = styled.div(({ theme }) => ({
  fontSize: '17px',
  lineHeight: '20px',
  padding: '0.75rem 1rem',
  background: theme.background.secondary,
  display: 'flex',
  alignItems: 'center',
  margin: '1rem auto 1rem 0',
  borderRadius: '10px',
  marginBottom: '1rem',
  '& img': {
    marginRight: '1rem',
  },
  [responsive.md]: {
    fontSize: '14px',
    lineHeight: '18px',
  },
}));

const BoldText = styled.span({
  fontWeight: 500,
});

export function Addresses() {
  const validatorAddress = getValidatorVars().address;
  const nominatorAddress = getNominatorVars().address;

  return (
    <ContractAddresses>
      <LargeHeading>Contract addresses</LargeHeading>
      <AddressesWarning>
        <img src="/images/warning-icon.svg" loading="lazy" alt="" />
        <div>
          <BoldText>BE WARY OF SCAMMERS</BoldText>
          <div>
            The chances are high that there will be new scam schemes related to staking. Only rely upon the information
            which you can find in the official Synternet social media channels. Our admins will not send you private
            messages related to staking and nodes. You are responsible for the safety of your assets.
          </div>
        </div>
      </AddressesWarning>
      <AddressesWarning>
        <img src="/images/important.svg" loading="lazy" alt="" />
        <div>
          <BoldText>DO NOT SEND YOUR TOKENS DIRECTLY TO STAKING CONTRACT ADDRESSES</BoldText>
          <div>
            Doing so will lead to losing your assets irreversibly. A two-step procedure must be done to deposit the
            tokens: approval on NOIA token contract and deposit on Validator or Nominator smart contract. Use the
            staking Dashboard to perform any staking actions.
          </div>
        </div>
      </AddressesWarning>
      <AddressList>
        <AddressItem>
          <h3>NOIA TOKEN</h3>
          <div>
            <SmallAddressLink
              href={`https://etherscan.io/address/${noiaTokenAddress}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {noiaTokenAddress}
            </SmallAddressLink>
            <CopyAddress text={noiaTokenAddress} />
          </div>
        </AddressItem>
        <AddressItem>
          <h3>NOIA VALIDATOR</h3>
          <div>
            <SmallAddressLink
              href={`https://etherscan.io/address/${validatorAddress}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {validatorAddress}
            </SmallAddressLink>
            <CopyAddress text={validatorAddress} />
          </div>
        </AddressItem>
        <AddressItem>
          <h3>NOIA NOMINATOR</h3>
          <div>
            <SmallAddressLink
              href={`https://etherscan.io/address/${nominatorAddress}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {nominatorAddress}
            </SmallAddressLink>
            <CopyAddress text={nominatorAddress} />
          </div>
        </AddressItem>
      </AddressList>
    </ContractAddresses>
  );
}
