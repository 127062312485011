import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { bigParse, calculateAvailableUnstake, toFixed } from '../../utils/calculations';
import { formatNumber, formatThousand } from '../../utils/numbers';
import { validateAvailableAmount } from '../../utils/validation';
import {
  AvailableStakeText,
  LargeHeading,
  StakeAmount,
  StakeAmountLabel,
  StakeError,
  StakeInput,
  StakeLabel,
  StakingTab,
  StakingWindow,
  StyledButton,
  ValueHeading,
} from '../main/common';
import { UnstakeImage } from '../main/MainTabs/common';
import { StakeTabStats } from '../main/StakeTabStats';
import { TooltipIcon } from '../main/TooltipIcon';
import { nominatorState, requestUnstake } from './slice';
import { UnstakeList } from './UnstakeList';

const BondedText = styled(AvailableStakeText)({
  marginTop: '0.375rem',
});

const RequestUnstakeButton = styled(StyledButton)({
  '&.MuiButton-root': {
    marginTop: '1.5rem',
  },
});

const BondAmount = styled.span({
  paddingLeft: '5px',
});

export function UnstakeTab() {
  const { globalInfo, userBalances, unstakeBonded, transactions } = useSelector(nominatorState);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');

  const requestTransactionInProgress = useMemo(
    () => !!transactions.find((x) => x.action === 'request' && x.pending),
    [transactions],
  );

  const availableUnstake = useMemo(() => {
    return calculateAvailableUnstake(userBalances?.userNominatorBalance);
  }, [userBalances?.userNominatorBalance]);

  const error = useMemo(() => {
    return validateAvailableAmount(amount, availableUnstake);
  }, [amount, availableUnstake]);

  const requestEnabled = !error && amount.length !== 0 && !requestTransactionInProgress;

  const handleUnstake = useCallback(() => {
    if (!requestEnabled) {
      return;
    }

    dispatch(requestUnstake({ amount }));
    setAmount('');
  }, [amount, requestEnabled, dispatch]);

  const handleChangeAmount = useCallback((evt) => {
    const val = evt.target.value;
    setAmount(val);
  }, []);

  const handleAmountClick = useCallback(() => {
    if (availableUnstake === '0') {
      return;
    }

    handleChangeAmount({ target: { value: availableUnstake } });
  }, [availableUnstake, handleChangeAmount]);

  return (
    <StakingTab>
      <StakingWindow>
        <TooltipIcon
          variant="absolute"
          text="You can unstake your tokens after the bonding period of 7 days has come to an end. By removing your stake, you do not affect the amount you had already accumulated."
        />
        <UnstakeImage />
        <LargeHeading>Unstake NOIA</LargeHeading>
        <StakeInput $error={!!error}>
          <StakeLabel>
            <StakeAmountLabel>Amount:</StakeAmountLabel>
            {error && <StakeError>{error}</StakeError>}
          </StakeLabel>
          <input type="text" placeholder="0.0" value={amount} onChange={handleChangeAmount} />
          <ValueHeading>NOIA</ValueHeading>
        </StakeInput>
        <AvailableStakeText>
          Available to unstake:
          <StakeAmount onClick={handleAmountClick}>
            {formatNumber(toFixed(availableUnstake, 18), formatThousand)} NOIA
          </StakeAmount>
        </AvailableStakeText>
        {unstakeBonded !== '0' && (
          <BondedText>
            Bonded:<BondAmount>{formatNumber(toFixed(unstakeBonded, 18), formatThousand)} NOIA</BondAmount>
          </BondedText>
        )}
        <RequestUnstakeButton $disabled={!requestEnabled} onClick={handleUnstake}>
          {requestTransactionInProgress ? 'Requesting...' : 'Request to unstake'}
        </RequestUnstakeButton>
        <UnstakeList />
      </StakingWindow>
      <StakeTabStats
        items={[
          {
            label: 'Your current stake:',
            number: formatNumber(toFixed(bigParse(userBalances?.userNominatorBalance || '0')), formatThousand),
            value: 'NOIA',
          },
          {
            label: 'Nominator stake limit:',
            number: formatNumber(globalInfo?.stake_size_limit_max, formatThousand),
            value: 'NOIA',
            tooltip:
              'This is the maximum amount of tokens per one Nominator. You can stake more if you wish but your reward will not be affected.',
          },
          {
            label: 'Available to unstake:',
            number: formatNumber(availableUnstake, formatThousand),
            value: 'NOIA',
          },
        ]}
      />
    </StakingTab>
  );
}
