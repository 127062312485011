import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatNumber, formatThousand } from '../../utils/numbers';
import { StyledButton } from '../main/common';
import { nominatorState, unstake } from './slice';

const Wrapper = styled.div({
  width: '100%',
  padding: '0 15px 15px 15px',
});

const Item = styled.div<{ $disabled?: boolean; $border?: boolean }>(({ $disabled, $border }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1rem 0',
  borderBottom: $border ? '0.5px solid #D2D2E0' : 'none',
  color: $disabled ? '#9F9FAC' : '#161515',
}));

const DateText = styled.div({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
  fontSize: '16px',
});

const Amount = styled.div({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  fontSize: '16px',
  alignItems: 'flex-end',
});

const TotalAmount = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Unit = styled.div({
  fontSize: '12px',
  fontWeight: 600,
  paddingLeft: '3px',
});

const TimeLeft = styled.div({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
  fontSize: '12px',
});

const ClaimAllButton = styled(StyledButton)({
  '&.MuiButton-root': {
    fontSize: '14px',
    padding: '0.3125rem 0',
    margin: '0',
    lineHeight: '16px',
    minWidth: '88px',
    maxWidth: '88px',
  },
});

const TotalText = styled(DateText)({
  fontSize: '12px',
  alignItems: 'center',
  textTransform: 'uppercase',
});

export function UnstakeList() {
  const dispatch = useDispatch();
  const { unstakeItems, unstakeEnabled, transactions } = useSelector(nominatorState);

  const unstakeTransactionInProgress = useMemo(
    () => !!transactions.find((x) => x.action === 'unstake' && x.pending),
    [transactions],
  );

  const claimEnabled = unstakeEnabled !== '0' && !unstakeTransactionInProgress;

  const handleClaimAll = useCallback(() => {
    if (claimEnabled) {
      dispatch(unstake());
    }
  }, [claimEnabled, dispatch]);

  if (unstakeItems.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {unstakeItems.map((item) => (
        <Item key={item.id} $disabled={!item.enabled} $border>
          <DateText>{item.dateText}</DateText>
          <Amount>
            {formatNumber(item.amount, formatThousand)}
            <Unit>NOIA</Unit>
          </Amount>
          <TimeLeft>{item.timeLeft}</TimeLeft>
        </Item>
      ))}
      <Item>
        <TotalText>Total:</TotalText>
        <TotalAmount>
          <Amount>
            {formatNumber(unstakeEnabled, formatThousand)}
            <Unit>NOIA</Unit>
          </Amount>
        </TotalAmount>
        <TimeLeft>
          <ClaimAllButton $disabled={!claimEnabled} onClick={handleClaimAll}>
            {unstakeTransactionInProgress ? 'Claiming...' : 'Claim All'}
          </ClaimAllButton>
        </TimeLeft>
      </Item>
    </Wrapper>
  );
}
