import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Question } from '../../assets/img/question_mark.svg';
import { responsive } from './theme';

const Tooltip = styled.div<{ $alignLeft?: boolean; $width?: string }>(({ theme, $alignLeft, $width }) => ({
  position: 'absolute',
  top: '0',
  right: $alignLeft ? 'auto' : '0',
  left: $alignLeft ? '0' : 'auto',
  padding: $alignLeft ? '1rem 1.5rem 1rem 2rem' : '1rem 2rem 1rem 1.5rem',
  background: theme.tooltip.background,
  color: theme.tooltip.text,
  borderRadius: '10px',
  alignItems: 'center',
  display: 'none',
  width: $width || '300px',
  zIndex: 1,
}));

const TooltipImage = styled(Question)(({ theme }) => ({
  width: '15px',
  height: '15px',
  color: theme.background.primary,
  [responsive.sm]: {
    width: '10px',
    height: '10px',
  },
}));

export const TooltipWrapper = styled.div<{ $show?: boolean }>(({ theme, $show }) => {
  const showStyles = $show
    ? {
        zIndex: 2,
        [`${TooltipImage}`]: {
          zIndex: 3,
          color: theme.tooltip.icon.active,
        },
        [`${Tooltip}`]: {
          display: 'flex',
        },
      }
    : {};

  return {
    position: 'relative',
    marginBottom: 'auto',
    marginLeft: '0.5rem',
    flexShrink: 0,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    ...showStyles,
  };
});

const AbsoluteTooltipWrapper = styled(TooltipWrapper)({
  position: 'absolute',
  right: '14px',
  top: '14px',
});

const NoMarginTooltipWrapper = styled(TooltipWrapper)({
  margin: '0 0.5rem 0 0',
});

interface TooltipIconProps {
  text: string;
  variant?: 'absolute' | 'noMargin';
  alignLeft?: boolean;
  tooltipWidth?: string;
}

export const TooltipIcon = ({ text, variant, alignLeft, tooltipWidth }: TooltipIconProps) => {
  const [showing, setShowing] = useState<boolean>(false);

  const handleClickTooltip = useCallback(() => {
    setShowing(false);
  }, []);
  const handleHoverTooltipIcon = useCallback(() => {
    setShowing(true);
  }, []);
  const handleHoverOutTooltipWrapper = useCallback(() => {
    setShowing(false);
  }, []);

  const content = (
    <>
      <TooltipImage onMouseEnter={handleHoverTooltipIcon} />
      <Tooltip $alignLeft={alignLeft} $width={tooltipWidth} onClick={handleClickTooltip}>
        {text}
      </Tooltip>
    </>
  );

  const wrapperProps = {
    $show: showing,
    onMouseLeave: handleHoverOutTooltipWrapper,
  };

  if (variant === 'absolute') return <AbsoluteTooltipWrapper {...wrapperProps}>{content}</AbsoluteTooltipWrapper>;
  else if (variant === 'noMargin') return <NoMarginTooltipWrapper {...wrapperProps}>{content}</NoMarginTooltipWrapper>;
  return <TooltipWrapper {...wrapperProps}>{content}</TooltipWrapper>;
};
