import { noValue } from './calculations';

export function formatThousand(num: number | string) {
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function formatNumber(num?: number | string, formatter?: (num: number | string) => string) {
  if (num === undefined) {
    return noValue;
  }

  const text = num.toString();

  if (!formatter) {
    return text;
  }

  return formatter(text);
}
