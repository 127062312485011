import { eventChannel } from 'redux-saga';
import { call, fork, put, select, take, takeLatest } from 'typed-redux-saga';
import { getContractVars, getNominatorVars, getValidatorVars } from '../../utils/env';
import {
  checkConnectedAccounts,
  connect,
  fetchNetworkName,
  initializeMetaMask,
  metaMaskInstalled,
  subscribeToAccountsChanged,
  subscribeToNetworkChanged,
} from '../../utils/metamask';
import {
  accountsChanged,
  closeConnectModal,
  connectMetaMask,
  mainState,
  resetUserInfo,
  resetWallet,
  setUserWallet,
  toggleBurgerMenu,
} from './slice';

function* initialize() {
  yield* call(initializeMetaMask, {
    contract: getContractVars(),
    validator: getValidatorVars(),
    nominator: getNominatorVars(),
  });
  yield* call(checkMetaMaskConnectedAccount);
  yield* fork(watchAccountsChanged);
  yield* fork(watchNetworkChanged);
}

function* createAccountsChangedChannel() {
  if (!metaMaskInstalled()) {
    return;
  }

  return eventChannel<string[]>((emitter) => {
    subscribeToAccountsChanged(emitter);
    return () => {
      // do nothing
    };
  });
}

function* watchResetUserInfo() {
  yield* put(resetWallet());
}

function* watchAccountsChanged() {
  const chan = yield* call(createAccountsChangedChannel);
  if (!chan) {
    return;
  }

  try {
    while (true) {
      const accounts = yield* take<string[]>(chan);
      yield* put(resetUserInfo());
      if (accounts.length > 0) {
        const address = accounts[0];
        const networkName = yield* call(fetchNetworkName);
        yield* put(setUserWallet({ address, networkName }));
      }
    }
  } catch (err) {
    yield* call(showError, err);
  } finally {
  }
}

function* createNetworkChangedChannel() {
  if (!metaMaskInstalled()) {
    return;
  }

  return eventChannel<string>((emitter) => {
    subscribeToNetworkChanged(emitter);
    return () => {
      // do nothing
    };
  });
}

function* watchNetworkChanged() {
  const chan = yield* call(createNetworkChangedChannel);
  if (!chan) {
    return;
  }

  try {
    while (true) {
      const networkName = yield* take<string>(chan);
      const { userWallet } = yield* select(mainState);
      if (userWallet) {
        yield* put(resetUserInfo());
        const { address } = userWallet;
        yield* put(setUserWallet({ address, networkName }));
      }
    }
  } catch (err) {
    yield* call(showError, err);
  } finally {
  }
}

function* checkMetaMaskConnectedAccount() {
  if (!metaMaskInstalled()) {
    return;
  }

  try {
    const accounts = yield* call(checkConnectedAccounts);
    if (accounts.length === 0) {
      return;
    }

    const address = accounts[0];
    const networkName = yield* call(fetchNetworkName);
    yield* put(setUserWallet({ address, networkName }));
  } catch (err) {
    yield* call(showError, err);
  }
}

export function* showError(err: any) {
  yield* call(console.error, err);
}

function* watchConnectMetaMask() {
  if (!metaMaskInstalled()) {
    return;
  }

  try {
    const accounts = yield* call(connect);
    const networkName = yield* call(fetchNetworkName);
    const address = accounts[0];
    yield* put(setUserWallet({ address, networkName }));
  } catch (err) {
    yield* call(showError, err);
  }
}

function* watchToggleBurgerMenu() {
  const { connectModalVisible } = yield* select(mainState);
  if (connectModalVisible) {
    yield* put(closeConnectModal());
  }
}

export function* mainSagas() {
  yield* takeLatest(connectMetaMask, watchConnectMetaMask);
  yield* takeLatest(toggleBurgerMenu, watchToggleBurgerMenu);
  yield* takeLatest(accountsChanged, watchAccountsChanged);
  yield* takeLatest(resetUserInfo, watchResetUserInfo);
  yield* initialize();
}
