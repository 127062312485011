import React from 'react';
import styled from 'styled-components';
import { LargeHeading, Paragraph, StyledButton } from '../../common';
import { responsive } from '../../theme';

const OverviewDiscord = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '3rem 0',
  borderTop: `1px solid ${theme.border.secondary}`,
  marginTop: '2rem',
  justifyContent: 'center',
  [responsive.md]: {
    padding: '2rem 0',
  },
}));

const DiscordContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '& > p': {
    width: '100%',
  },
  [`${StyledButton}`]: {
    margin: '2rem auto 0 auto',
  },
  [responsive.md]: {
    '& > p': {
      maxWidth: '100%',
    },
    [`${StyledButton}`]: {
      margin: '1.5rem auto 1rem auto',
    },
  },
  [responsive.sm]: {
    alignItems: 'center',
  },
});

const NoDecorationLink = styled.a({
  textDecoration: 'none',
});

const TextContainer = styled.div({
  flex: 1,
  marginRight: 16,
});

const JoinButtons = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '& > *:first-child': {
    marginBottom: 24,
  },
});

const JoinButton = styled(StyledButton)({
  '&.MuiButton-root': {
    marginTop: 0,
  },
});

export function InstantMessaging() {
  return (
    <OverviewDiscord>
      <TextContainer>
        <LargeHeading>Community Channels</LargeHeading>
        <DiscordContent>
          <Paragraph>
            Visit Synternet Discord and Telegram channels to get guidance from our tech-savvy community managers and
            ambassadors.
          </Paragraph>
        </DiscordContent>
      </TextContainer>

      <JoinButtons>
        <NoDecorationLink href="https://discord.gg/Ze7Kswye8B" target="_blank" rel="noreferrer">
          <JoinButton>Join Discord</JoinButton>
        </NoDecorationLink>
        <NoDecorationLink href="https://t.me/Synternet" target="_blank" rel="noreferrer">
          <JoinButton>Join Telegram</JoinButton>
        </NoDecorationLink>
      </JoinButtons>
    </OverviewDiscord>
  );
}
