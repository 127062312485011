import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatShortAddress } from '../../utils/format';
import { CloseButton, LargeHeading, ModalContent, ModalWrapper, ModalWrapperBackground, StyledButton } from './common';
import { CopyAddress } from './CopyAddress';
import { closeConnectModal, connectMetaMask, mainState } from './slice';
import { responsive } from './theme';

const WalletsHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  marginBottom: '2rem',
  alignItems: 'flex-end',
  '& > div': {
    display: 'flex',
    marginRight: '10px',
    '& > span': {
      fontSize: '18px',
      color: '#B9B9B9',
      letterSpacing: '0.5px',
      textDecoration: 'underline',
    },
    '& > div > div': {
      border: '1px solid #9C9CB7',
      transform: 'translate(10px, -10px)',
      width: '500px',
      '& img': {
        top: '10px',
        right: '10px',
      },
    },
    '& img': {
      width: '12px',
      height: '12px',
    },
  },
});

const WalletInfo = styled.div(({ theme }) => ({
  color: theme.text.primary,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  fontSize: '18px',
  marginRight: '2rem',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '0.5rem',
    },
  },
  '& img': {
    width: '120px',
    marginBottom: '2rem',
  },
}));

const WalletActions = styled.div({
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'flex-end',
  [`${StyledButton}:not(:last-child)`]: {
    marginBottom: '1rem',
  },
});

const ConnectedContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  alignItems: 'center',
  fontSize: '18px',
  lineHeight: '20px',
  marginRight: 0,
  marginTop: '2rem',
  marginLeft: 'auto',
});

const ConnectedRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const WalletOption = styled.div(({ theme }) => ({
  padding: '2rem',
  borderRadius: '20px',
  border: `1px solid ${theme.border.primary}`,
  marginBottom: '1rem',
  display: 'flex',
  position: 'relative',
  [`${WalletInfo}`]: {
    marginRight: 0,
  },
  [`${WalletActions}`]: {
    alignItems: 'center',
    marginRight: 'auto',
  },
  [`${StyledButton}`]: {
    marginLeft: 'auto',
  },
  [`${ConnectedRow}`]: {
    justifyContent: 'center',
  },
  [responsive.sm]: {
    flexDirection: 'column',
    [`${WalletActions}`]: {
      width: '100%',
    },
    [`${ConnectedContainer}`]: {
      marginLeft: 0,
    },
    [`${StyledButton}`]: {
      '&:first-child': {
        marginTop: '2rem',
      },
      margin: '0',
    },
  },
}));

const DisabledOptionOverlay = styled.div({
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  right: '-1px',
  bottom: '-1px',
  background: 'rgba(255, 255, 255, 0.25)',
  borderRadius: '20px',
  zIndex: 1,
});

const WalletData = styled.span<{ $strong?: boolean }>(({ theme, $strong }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: $strong ? theme.text.primary : theme.text.secondary,
  marginLeft: '15px',
  fontWeight: $strong ? 500 : 400,
}));

const Circle = styled.div({
  borderRadius: '50%',
  background: '#32BE32',
  width: '8px',
  height: '8px',
  marginLeft: '8px',
});

const ConnectedDescription = styled.div({
  fontSize: '14px',
  color: '#B9B8B8',
  marginRight: '16px',
});

const WalletLink = styled.a(({ theme }) => ({
  color: theme.text.secondary,
  fontSize: '18px',
}));

export function ConnectWalletDesktop() {
  const { userWallet, connectModalVisible } = useSelector(mainState);
  const dispatch = useDispatch();

  const handleConnect = useCallback(() => {
    dispatch(connectMetaMask());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(closeConnectModal());
  }, [dispatch]);

  return (
    <ModalWrapper $open={connectModalVisible}>
      <ModalWrapperBackground onClick={handleClose} />
      <ModalContent $shadow>
        <WalletsHeader>
          <LargeHeading>Select Wallet</LargeHeading>
          <div>
            <WalletLink href="https://ethereum.org/en/wallets" target="_blank" rel="noreferrer noopener">
              What is a wallet?
            </WalletLink>
          </div>
        </WalletsHeader>
        <WalletOption>
          <WalletInfo>
            <img src="/images/metamask-white.svg" loading="eager" alt="" />
            <div>
              Account:
              <WalletData $strong={!!userWallet}>
                {userWallet ? (
                  <>
                    {formatShortAddress(userWallet.address)}
                    <CopyAddress small text={userWallet.address} />
                  </>
                ) : (
                  'Not Connected'
                )}
              </WalletData>
            </div>
            <div>
              Network:
              <WalletData $strong={!!userWallet}>{userWallet ? userWallet.networkName : 'Not Connected'}</WalletData>
            </div>
          </WalletInfo>
          <WalletActions>
            {!userWallet && <StyledButton onClick={handleConnect}>Connect to MetaMask</StyledButton>}
            {userWallet && (
              <ConnectedContainer>
                <div>
                  <ConnectedRow>
                    Connected <Circle />
                  </ConnectedRow>
                  <ConnectedDescription>Disconnect on MetaMask</ConnectedDescription>
                </div>
              </ConnectedContainer>
            )}
          </WalletActions>
        </WalletOption>
        <WalletOption>
          <DisabledOptionOverlay />
          <WalletInfo>
            <img src="/images/walletconnect-banner-1.svg" loading="eager" alt="" />
            <div>
              Account:<WalletData>Not Connected</WalletData>
            </div>
            <div>
              Network:<WalletData>Not Connected</WalletData>
            </div>
          </WalletInfo>
          <WalletActions>
            {!userWallet && <StyledButton>Connect to WalletConnect</StyledButton>}
            {!userWallet && <StyledButton>Reset QR</StyledButton>}
          </WalletActions>
        </WalletOption>
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </ModalContent>
    </ModalWrapper>
  );
}
