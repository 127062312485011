import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateEmailSecret } from '../../../../utils/validation';
import { setEmailVerificationSecret } from '../../../validator/slice';
import { StyledButton } from '../../common';
import { Content, Input, InputBlock, InputWrapper, Label, LabelRow, SubmitWrapper, ValidationText } from './common';

export function SecretStep() {
  const [secret, setSecret] = useState('');
  const [validation, setValidation] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleSecretChange = useCallback(
    (evt) => {
      setSecret(evt.target.value);
      if (validation) {
        setValidation(undefined);
      }
    },
    [validation],
  );

  const handleSubmit = useCallback(() => {
    const val = secret.trim();
    const valid = validateEmailSecret(val);
    if (!valid) {
      setValidation('Invalid secret');
      return;
    }

    setSubmitting(true);
    dispatch(setEmailVerificationSecret({ secret: val }));
  }, [secret, dispatch]);

  return (
    <Content>
      <InputBlock>
        <LabelRow>
          <Label>Secret from e-mail</Label>
          {validation && <ValidationText>{validation}</ValidationText>}
        </LabelRow>
        <InputWrapper>
          <Input $error={!!validation} value={secret} onChange={handleSecretChange} />
        </InputWrapper>
      </InputBlock>
      <SubmitWrapper>
        <StyledButton $sm onClick={handleSubmit} disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </StyledButton>
      </SubmitWrapper>
    </Content>
  );
}
