export function validateCurrency(amount: string): boolean {
  const regex = /^[0-9]\d*(?:\.\d{0,18})?$/;
  return regex.test(amount);
}

export function validateStake({
  input,
  availableAmount,
  userBalance,
}: {
  input: string;
  availableAmount: string;
  userBalance: string;
}): string | undefined {
  if (input.length === 0) {
    return;
  }

  const validCurrency = validateCurrency(input);
  if (!validCurrency) {
    return 'Invalid amount';
  }

  const inputNum = parseFloat(input);

  if (inputNum > parseFloat(userBalance)) {
    return 'Insufficient token amount';
  }

  if (inputNum > parseFloat(availableAmount)) {
    return 'Amount exceeds stake limit';
  }

  if (inputNum === 0) {
    return 'Invalid amount';
  }
}

export function validateAvailableAmount(input: string, availableAmount: string): string | undefined {
  if (input.length === 0) {
    return;
  }

  const validCurrency = validateCurrency(input);
  if (!validCurrency) {
    return 'Invalid amount';
  }

  if (parseFloat(input) > parseFloat(availableAmount)) {
    return 'Insufficient token amount';
  }

  if (parseFloat(input) === 0) {
    return 'Invalid amount';
  }
}

export function validateEmail(email: string): boolean {
  const regex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regex.test(email);
}

export function validateEmailSecret(secret: string): boolean {
  const regex = /^[0-9a-f]+$/;
  if (secret.length !== 8) {
    return false;
  }

  return regex.test(secret);
}
