import React from 'react';
import styled from 'styled-components';
import { Content } from './common';

const Text = styled.div({
  fontSize: '18px',
});

export function SuccessStep() {
  return (
    <Content>
      <Text>Validator was successfully registered. You can now get validator key.</Text>
    </Content>
  );
}
