import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { validatorState } from '../../validator/slice';
import { BalanceField } from '../common';
import { TooltipIcon, TooltipWrapper } from '../TooltipIcon';
import { AccountDashboardProps, Circle, InfoBlock, Status, StatusText, StatusWrapper } from './common';

const Text = styled(StatusText)({
  flex: 0,
  whiteSpace: 'nowrap',
});

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [`${TooltipWrapper}`]: {
    marginTop: '14px',
  },
});

export function ValidatorWhitelistStatus({ styled }: AccountDashboardProps) {
  const { userStake } = useSelector(validatorState);
  const whitelisted = !!userStake?.whitelisted;
  const text = whitelisted ? 'Whitelisted' : 'Not whitelisted';
  const color = whitelisted ? '#01994D' : '#ADB1D1';

  return (
    <InfoBlock $styled={styled}>
      <Header>
        <h5>Whitelist status:</h5>
        <TooltipIcon
          text="Only whitelisted wallets are considered when calculating rewards. Green whitelist status means your Node is meeting the uptime requirement."
          tooltipWidth="400px"
        />
      </Header>
      <BalanceField>
        <StatusWrapper>
          <Status>
            <Circle $color={color} />
            <Text>{text}</Text>
          </Status>
        </StatusWrapper>
      </BalanceField>
    </InfoBlock>
  );
}
