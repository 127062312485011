import React from 'react';
import { useSelector } from 'react-redux';
import { bigParse, calculateUnclaimedRewards, toFixed } from '../../../utils/calculations';
import { formatNumber, formatThousand } from '../../../utils/numbers';
import { nominatorState } from '../../nominator/slice';
import { AccountDashboardProps, BalanceWrapper, DashboardItem } from './common';

export function NominatorDashboard({ styled }: AccountDashboardProps) {
  const { userBalances, unstakeEnabled, userStake } = useSelector(nominatorState);

  if (!userBalances) {
    return null;
  }

  const unclaimed = calculateUnclaimedRewards(userStake?.claimProof?.reward, userBalances?.totalPayouts);

  return (
    <BalanceWrapper $styled={styled}>
      <DashboardItem
        styled={styled}
        title="Wallet token balance:"
        amount={formatNumber(toFixed(bigParse(userBalances.userBalance)), formatThousand)}
      />
      <DashboardItem
        styled={styled}
        title="Staked token balance:"
        amount={formatNumber(toFixed(bigParse(userBalances.userNominatorBalance)), formatThousand)}
      />
      <DashboardItem styled={styled} title="Ready to unstake:" amount={formatNumber(unstakeEnabled, formatThousand)} />
      <DashboardItem
        styled={styled}
        title="Ready to claim:"
        amount={formatNumber(toFixed(bigParse(unclaimed)), formatThousand)}
      />
    </BalanceWrapper>
  );
}
