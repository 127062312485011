import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '../../../assets/img/arrow-icon.svg';
import { Transaction } from '../../../types';
import { toFixed } from '../../../utils/calculations';
import { getTransactionUrl } from '../../../utils/env';
import { formatThousand } from '../../../utils/numbers';

const ActivityItem = styled.div(({ theme }) => ({
  borderTop: `1px solid ${theme.border.secondary}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '25px 0',
  color: theme.text.secondary,
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ActivityStake = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    flexShrink: 0,
  },
  '& span': {
    marginLeft: '0.25rem',
    color: theme.text.primary,
  },
}));

const ActivityValue = styled.div<{ $status: string }>(({ theme, $status }) => ({
  color: $status === 'staked' ? theme.text.linkDisabled : theme.text.link,
  fontWeight: 500,
}));

const ValidatorsText = styled.div({
  marginTop: '6px',
});

const ActivityInfo = styled.div(({ theme }) => ({
  color: theme.text.secondary,
  fontSize: '14px',

  '& a': {
    color: theme.text.secondary,
    marginTop: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      color: '#111',
      '& svg path': {
        stroke: '#111',
        strokeOpacity: 1,
      },
    },
    '& svg': {
      marginLeft: '2px',
    },
  },
}));

const ActivityStatus = styled.div({
  marginLeft: '3px',
  fontSize: '14px',
  color: '#B9B8B8',
});

const ContractApprovedText = styled.div({
  color: '#111',
});

interface TransactionItemProps {
  transaction: Transaction;
}

export function TransactionItem({ transaction }: TransactionItemProps) {
  const { hash, action, amount, date, pending, failReason, contractType } = transaction;
  const url = getTransactionUrl() + hash;
  const contract = contractType === 'validator' ? 'Validators' : 'Nominators';

  return (
    <ActivityItem>
      <div>
        <ActivityStake>
          {action !== 'approve' && (
            <>
              <ActivityValue $status={actionNames[action]}>{formatThousand(toFixed(amount))} NOIA</ActivityValue>
              <span>{actionNames[action]}</span>
            </>
          )}
          {action === 'approve' && <ContractApprovedText>Contract approved</ContractApprovedText>}
          {pending && <ActivityStatus>(pending)</ActivityStatus>}
          {failReason && <ActivityStatus>({failReason})</ActivityStatus>}
        </ActivityStake>
        <ValidatorsText>NOIA {contract}</ValidatorsText>
      </div>
      <ActivityInfo>
        <div>{date}</div>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <div>View Tx</div>
          <ArrowIcon />
        </a>
      </ActivityInfo>
    </ActivityItem>
  );
}

const actionNames: { [key in Transaction['action']]: string } = {
  approve: 'approved',
  stake: 'staked',
  unstake: 'unstaked',
  claim: 'claimed',
  request: 'requested',
};
