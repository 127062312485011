import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { validatorState } from '../../validator/slice';
import { mainState } from '../slice';
import { AccountDashboard } from './AccountDashboard';
import { Transactions } from './Transactions';

const WalletInfoText = styled.div({
  fontSize: '17px',
  margin: '50px 20px 1rem 20px',
  textAlign: 'center',
});

interface AccountTabProps {
  styled?: boolean;
}

export const AccountInfo = ({ styled }: AccountTabProps) => {
  const { accountTab } = useSelector(mainState);
  const { userBalances } = useSelector(validatorState);

  if (!userBalances) {
    return <WalletInfoText>This section will remain empty until you connect your wallet.</WalletInfoText>;
  }

  return (
    <div style={styled ? { width: '360px', margin: '0 auto 2rem auto' } : {}}>
      {accountTab === 'Dashboard' ? <AccountDashboard styled={styled} /> : <Transactions />}
    </div>
  );
};
