import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { breakpoints, responsive } from './theme';

export const WidthWrapper = styled.div<{ $fullHeight?: boolean }>(({ $fullHeight }) => ({
  maxWidth: breakpoints.xl,
  width: 'calc(100% - 8rem)',
  margin: '0 auto',
  height: $fullHeight ? '100%' : 'initial',
  [responsive.lg]: {
    width: 'calc(100% - 6rem)',
  },
  [responsive.md]: {
    pwidth: 'calc(100% - 4rem)',
  },
}));

export const StyledButton = styled(Button)<{ $grow?: boolean; $disabled?: boolean; $sm?: boolean }>(
  ({ theme, $grow, $disabled, $sm }) => ({
    '&.MuiButton-root': {
      fontFamily: 'inherit',
      border: $disabled ? theme.button.primary.disabled.border : theme.button.primary.active.border,
      borderRadius: '30px',
      padding: $sm ? '0.25rem 1rem' : '0.5rem 1rem',
      textTransform: 'none',
      lineHeight: $sm ? '1.64' : '26px',
      minWidth: $sm ? '170px' : '220px',
      fontSize: '18px',
      fontWeight: 'normal',
      flexGrow: $grow ? 1 : 0,
      cursor: $disabled ? 'not-allowed' : 'pointer',
      color: $disabled ? theme.button.primary.disabled.color : theme.button.primary.active.color,
      background: $disabled ? theme.button.primary.disabled.background : theme.button.primary.active.background,
      '&:hover': $disabled
        ? {
            background: '#E9E9EF',
          }
        : {
            color: theme.button.primary.hover.color,
            background: theme.button.primary.hover.background,
            fontWeight: 500,
          },
      [responsive.sm]: {
        fontSize: '16px',
      },
    },
  }),
);

export const LargeHeading = styled.h2({
  fontWeight: 500,
  margin: 0,
  fontSize: '32px',
  [responsive.sm]: {
    fontSize: '21px',
  },
});

export const ValueHeading = styled.h2({
  fontWeight: 500,
  margin: '0 0 0 0.5rem',
  fontSize: '18px',
  [responsive.sm]: {
    fontSize: '16px',
  },
});

export const NumberText = styled.div({
  fontWeight: 300,
  fontSize: '36px',
  '& > span': {
    fontSize: '24px',
  },
  [responsive.sm]: {
    fontSize: '24px',
  },
});

export const SmallHeading = styled.div({
  fontWeight: 500,
  fontSize: '21px',
  margin: '0 0 0.5rem 0',
  [responsive.sm]: {
    fontSize: '18px',
  },
});

export const BalanceField = styled.div({
  border: '1px solid #D2D2E0',
  borderRadius: '10px',
  padding: '0.75rem 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
});

export const StakingTab = styled.div(({ theme }) => ({
  background: theme.background.secondary,
  borderRadius: '10px',
  padding: '3rem 3rem 2rem 3rem',
  marginTop: '2rem',
  position: 'relative',
  minHeight: '624px',
  display: 'flex',
  flexDirection: 'column',
}));

export const StakingWindow = styled.div({
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [`${StyledButton}`]: {
    margin: '2rem 0 2.5rem 0',
  },
});

export const StakeLabel = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StakeError = styled.div({
  color: '#DF4848',
  fontSize: '16px',
});

export const StakeAmountLabel = styled.div({
  textTransform: 'uppercase',
});

export const StakeInput = styled.div<{ $error?: boolean }>(({ $error }) => ({
  position: 'relative',
  margin: '2rem 0 0 0',
  [`${StakeLabel}`]: {
    fontSize: '16px',
    marginBottom: '0.5rem',
  },
  [`${ValueHeading}`]: {
    color: '#111111',
    position: 'absolute',
    right: '1.5rem',
    bottom: '0.5rem',
  },
  '& > input': {
    color: '#111111',
    background: '#F7F7FF',
    borderRadius: '6px',
    border: $error ? '1px solid #DF4848' : '1px solid #D2D2E0',
    padding: '0.75rem 5rem 0.75rem 1.5rem',
    fontSize: '24px',
  },
}));

export const AvailableStakeText = styled.div({
  fontSize: '16px',
  lineHeight: '20px',
  color: '#9F9FAC',
  marginTop: '1rem',
  textAlign: 'center',
  [responsive.sm]: {
    maxWidth: breakpoints.xs,
  },
});

export const ModalWrapper = styled.div<{ $open?: boolean }>(({ $open }) => ({
  display: $open ? 'flex' : 'none',
  zIndex: 11,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
}));

export const ModalWrapperBackground = styled.div({
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  backdropFilter: 'blur(5px)',
});

export const ModalContent = styled.div<{ $shadow?: boolean; $fullMobile?: boolean; $maxWidth?: string }>(
  ({ theme, $shadow, $fullMobile, $maxWidth }) => ({
    background: theme.background.secondary,
    color: theme.text.primary,
    borderRadius: '30px',
    padding: '2rem 3rem',
    width: 'calc(100% - 6rem)',
    zIndex: 1,
    maxWidth: $maxWidth ? $maxWidth : breakpoints.md,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: $shadow ? '0px 0px 50px rgba(0, 0, 0, 0.2)' : 'none',
    [responsive.sm]: {
      width: $fullMobile ? '100%' : 'calc(100% - 6rem)',
      borderRadius: $fullMobile ? 0 : '30px',
    },
  }),
);

export const CloseButton = styled(StyledButton)({
  '&.MuiButton-root': {
    minWidth: '170px',
    marginLeft: 'auto',
    padding: '0.25rem 1rem',
    [responsive.sm]: {
      fontSize: '16px',
    },
  },
});

export const Paragraph = styled.p<{ $maxWidth?: string }>(({ $maxWidth }) => ({
  fontWeight: 300,
  fontSize: '20px',
  lineHeight: '25px',
  margin: '1rem 0 0.5rem 0',
  maxWidth: $maxWidth || 'auto',
  [responsive.md]: {
    fontSize: '18px',
  },
}));

export const CenteredInfoText = styled.div({
  fontSize: '18px',
  margin: '1rem auto',
  textAlign: 'center',
  lineHeight: '23px',
});

export const LogoImage = styled.img({
  margin: '0 0 3rem 0',
  width: '180px',
  [responsive.md]: {
    width: '160px',
  },
  [responsive.sm]: {
    width: '100px',
  },
});

export const StakeAmount = styled.span({
  textDecoration: 'underline',
  textUnderlineOffset: '2px',
  cursor: 'pointer',
  paddingLeft: '5px',
  '&:hover': {
    opacity: 0.5,
  },
});

export const InputTextLink = styled(StakeAmount)({
  color: '#333',
});
