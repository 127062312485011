import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NominatorGlobalInfo } from '../../api';
import { ClaimProof, KycStep, NominatorBalances, Transaction, UnstakeItem } from '../../types';

export interface NominatorUnstakeItem {
  date: string;
  amount: string;
}

export interface NominatorStakeInfo {
  currentStake: string;
  totalInterest: string;
  kycCompleted: boolean;
  kycRequired: boolean;
  claimProof?: ClaimProof;
}

export interface NominatorState {
  globalInfo?: NominatorGlobalInfo;
  userBalances?: NominatorBalances;
  userStake?: NominatorStakeInfo;
  approving?: boolean;
  unstakeItems: UnstakeItem[];
  unstakeTotal: string;
  unstakeBonded: string;
  unstakeEnabled: string;
  transactions: Transaction[];
  kycModal?: {
    step: KycStep;
    link?: string;
    error?: string;
  };
}

const initialState: NominatorState = {
  transactions: [],
  unstakeItems: [],
  unstakeTotal: '0',
  unstakeBonded: '0',
  unstakeEnabled: '0',
};

const slice = createSlice({
  name: 'nominator',
  initialState,
  reducers: {
    setGlobalInfo: (state, { payload }: PayloadAction<{ globalInfo: NominatorGlobalInfo }>) => {
      state.globalInfo = payload.globalInfo;
    },
    setUserStake: (state, { payload }: PayloadAction<{ userStake: NominatorStakeInfo }>) => {
      state.userStake = payload.userStake;
    },
    setUserBalances: (state, { payload }: PayloadAction<{ userBalances: NominatorBalances }>) => {
      state.userBalances = payload.userBalances;
    },
    setTransactions: (state, { payload }: PayloadAction<{ transactions: Transaction[] }>) => {
      state.transactions = payload.transactions;
    },
    setApproving: (state, { payload }: PayloadAction<{ approving: boolean }>) => {
      state.approving = payload.approving;
    },
    setUnstakeItems: (
      state,
      { payload }: PayloadAction<{ items: UnstakeItem[]; total: string; bonded: string; enabled: string }>,
    ) => {
      state.unstakeItems = payload.items;
      state.unstakeTotal = payload.total;
      state.unstakeBonded = payload.bonded;
      state.unstakeEnabled = payload.enabled;
    },
    resetNominatorUserInfo: (state) => {
      return { ...initialState, globalInfo: state.globalInfo };
    },
    openKycModal: (state) => {
      state.kycModal = {
        step: 'validate-wallet',
      };
    },
    closeKycModal: (state) => {
      state.kycModal = undefined;
    },
    setKycModalStep: (state, { payload }: PayloadAction<{ step: KycStep }>) => {
      if (state.kycModal) {
        state.kycModal.step = payload.step;
      }
    },
    setKycLink: (state, { payload }: PayloadAction<{ link: string }>) => {
      if (state.kycModal) {
        state.kycModal.link = payload.link;
      }
    },
    setKycError: (state, { payload }: PayloadAction<{ error: string }>) => {
      if (state.kycModal) {
        state.kycModal.error = payload.error;
        state.kycModal.step = 'error';
      }
    },
  },
});

export const nominatorState = (state: RootState) => state.nominator;
export const nominatorReducer = slice.reducer;

export const {
  setGlobalInfo,
  setUserBalances,
  setUserStake,
  setTransactions,
  setApproving,
  setUnstakeItems,
  resetNominatorUserInfo,
  openKycModal,
  closeKycModal,
  setKycModalStep,
  setKycLink,
  setKycError,
} = slice.actions;
export const approve = createAction(`${slice.name}/approve`);
export const stake = createAction<{ amount: string }>(`${slice.name}/stake`);
export const requestUnstake = createAction<{ amount: string }>(`${slice.name}/requestUnstake`);
export const refreshUnstakeItems = createAction(`${slice.name}/refreshUnstakeItems`);
export const unstake = createAction(`${slice.name}/unstake`);
export const claim = createAction(`${slice.name}/claim`);
export const kycValidate = createAction(`${slice.name}/kycValidate`);
export const recomputeUnstakeInfo = createAction(`${slice.name}/recomputeUnstakeInfo`);
