import { HistoryItem, NominatorStakeResponse, ValidatorStakeResponse } from '../api';
import { NominatorStakeInfo, NominatorUnstakeItem } from '../features/nominator/slice';
import { ValidatorStakeInfo } from '../features/validator/slice';
import { ClaimProof, Transaction, UnstakeItem } from '../types';
import { bigParse, getHistoryItemAmount } from './calculations';
import { formatTransactionDate, formatUnstakeDate, fromUnixDate, getUnstakeTimeLeft } from './date';

export function mapHistoryItem(item: HistoryItem): Transaction {
  return {
    action: mapHistoryItemAction(item.tx_type),
    amount: getHistoryItemAmount(item),
    date: formatTransactionDate(fromUnixDate(item.block_ts)),
    hash: item.tx_hash,
    pending: false,
    contractType: item.contract,
  };
}

function mapHistoryItemAction(type: HistoryItem['tx_type']): Transaction['action'] {
  if (type === 'deposit') {
    return 'stake';
  }

  if (type === 'claim') {
    return 'claim';
  }

  if (type === 'withdraw') {
    return 'unstake';
  }

  if (type === 'request') {
    return 'request';
  }

  return 'unknown' as Transaction['action'];
}

export function mapValidatorStakeInfo(response: ValidatorStakeResponse): ValidatorStakeInfo {
  const {
    current_position,
    current_stake,
    total_interest,
    validator_status,
    validator_text,
    kyc_completed,
    claim_proof,
    stake_whitelisted,
    email_verified,
  } = response;

  let claimProof: ClaimProof | undefined = undefined;
  if (claim_proof) {
    claimProof = {
      block: claim_proof.block,
      proof: claim_proof.proof,
      reward: bigParse(claim_proof.reward, false),
    };
  }

  return {
    currentPosition: current_position,
    currentStake: bigParse(current_stake),
    totalInterest: bigParse(total_interest),
    validatorStatus: validator_status,
    validatorText: validator_text,
    kycCompleted: kyc_completed,
    claimProof: claimProof,
    whitelisted: stake_whitelisted,
    emailVerified: email_verified,
  };
}

export function mapNominatorStakeInfo(response: NominatorStakeResponse): NominatorStakeInfo {
  const { current_stake, total_interest, kyc_completed, kyc_required, claim_proof } = response;

  let claimProof: ClaimProof | undefined = undefined;
  if (claim_proof) {
    claimProof = {
      block: claim_proof.block,
      proof: claim_proof.proof,
      reward: bigParse(claim_proof.reward, false),
    };
  }

  return {
    currentStake: bigParse(current_stake),
    totalInterest: bigParse(total_interest),
    kycCompleted: kyc_completed,
    kycRequired: !!kyc_required,
    claimProof: claimProof,
  };
}

export function mapUnstakeItem(item: NominatorUnstakeItem): UnstakeItem {
  const { date, amount } = item;
  const timeLeft = getUnstakeTimeLeft(date);

  return {
    id: `${date}-${amount}`,
    enabled: timeLeft.enabled,
    timeLeft: timeLeft.text,
    dateText: formatUnstakeDate(date),
    amount,
    date,
  };
}

export function recomputeUnstakeItem(item: UnstakeItem): UnstakeItem {
  const { date } = item;
  const timeLeft = getUnstakeTimeLeft(date);

  return {
    ...item,
    enabled: timeLeft.enabled,
    timeLeft: timeLeft.text,
    dateText: formatUnstakeDate(date),
  };
}
