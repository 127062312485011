import { useParams } from 'react-router-dom';
import { MainTab, mainTabs, Scope, scopes } from '../../types';

export const mainRoute = '/:scope?/:tab?';
export const kycRoute = '/kyc/:id';

export interface MainRouteParams {
  scope: Scope;
  tab: MainTab;
}

export const getMainRoute = (scope?: Scope, tab?: MainTab) => {
  return mainRoute.replace(':scope?', scope || 'nominator').replace(':tab?', tab || 'overview');
};

export const getKycRoute = (id: string) => kycRoute.replace(':id', id);

export function useMainParams(): MainRouteParams {
  let { scope, tab } = useParams<MainRouteParams>();
  if (!scope || !scopes.includes(scope)) {
    scope = 'nominator';
  }

  tab = getTab(scope, tab);
  if (!tab || !mainTabs.includes(tab)) {
    tab = 'overview';
  }

  return { scope, tab };
}

function getTab(scope: Scope, tab: MainTab): MainTab {
  if (!tab) {
    return 'overview';
  }

  if (scope === 'validator' && tab === 'stake') {
    return 'overview';
  }

  if (!mainTabs.includes(tab)) {
    return 'overview';
  }

  return tab;
}
