import { compose } from 'redux';

export type Scope = 'validator' | 'nominator';
export const scopes: Scope[] = ['validator', 'nominator'];

export type MainTab = 'overview' | 'stake' | 'unstake' | 'claim';
export const mainTabs: MainTab[] = ['overview', 'stake', 'unstake', 'claim'];

export type ContractType = 'validator' | 'nominator';
export type Environment = 'local' | 'sandbox' | 'prod';
export type ValidatorStatus = 'OK' | 'NO_DATA' | 'ERROR';
export type KycStep = 'validate-wallet' | 'metamask-pending' | 'kyc-link' | 'error';

interface Env {
  ENVIRONMENT: Environment;
  API_URL: string;
  CONTRACT_ADDRESS: string;
  CONTRACT_ABI: string;
  VALIDATOR_ADDRESS: string;
  VALIDATOR_ABI: string;
  NOMINATOR_ADDRESS: string;
  NOMINATOR_ABI: string;
  TRANSACTION_URL: string;
}

export interface ContractInfo {
  address: string;
  abi: string;
}

export interface ValidatorBalances {
  userBalance: string;
  userStake: string;
  userValidatorBalance: string;
  allowance: string;
  totalPayouts: string;
}

export interface NominatorBalances {
  userBalance: string;
  userStake: string;
  userNominatorBalance: string;
  allowance: string;
  totalPayouts: string;
}

export interface Transaction {
  hash: string;
  pending?: boolean;
  date: string;
  amount: string;
  action: 'stake' | 'unstake' | 'approve' | 'claim' | 'request';
  contractType: ContractType;
  failReason?: 'repriced' | 'canceled';
}

export interface ClaimProof {
  block: number;
  proof: string[];
  reward: string;
}

export interface UnstakeItem {
  id: string;
  enabled: boolean;
  timeLeft: string;
  amount: string;
  dateText: string;
  date: string;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    _env_: Env;
    ethereum: any;
    web3: any;
  }
}
