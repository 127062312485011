import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { SmallHeading } from './common';
import { Modal } from './Modal';
import { mainState, setTransactionModal, TransactionStatus } from './slice';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StatusImage = styled.div({
  position: 'relative',
  marginRight: '1.5rem',
  '& img:nth-child(2)': {
    position: 'absolute',
    bottom: '2px',
    right: '-2px',
  },
});

const AnimatedImage = styled.img`
  animation: ${rotate} 1s infinite;
`;

const StatusText = styled.div({
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '3px',
  color: '#B3B3B3',
});

const TransactionStatusImage = styled.img({
  width: '20px',
  height: '20px',
});

const SmallHeadingWithoutMargin = styled(SmallHeading)({
  marginBottom: '0.25rem',
});

export function TransactionStatusModal() {
  const { transactionModal } = useSelector(mainState);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    if (transactionModal?.status === 'progress' || transactionModal?.locked) {
      return;
    }

    dispatch(setTransactionModal({}));
  }, [transactionModal, dispatch]);

  if (!transactionModal) {
    return null;
  }

  const { title, status, description, locked } = transactionModal;

  return (
    <Modal title="Transaction status" description={description} onClose={handleClose} closeHidden={locked}>
      <>
        <StatusImage>
          {locked ? (
            <AnimatedImage src="/images/stake-logo-filled.svg" loading="eager" alt="" />
          ) : (
            <img src="/images/stake-logo-filled.svg" loading="eager" alt="" />
          )}
          {status === 'error' && <TransactionStatusImage src="/images/error-sign.svg" loading="lazy" alt="" />}
        </StatusImage>
        <div>
          <StatusText>{statusTextMap[status]}</StatusText>
          <SmallHeadingWithoutMargin>{title}</SmallHeadingWithoutMargin>
        </div>
      </>
    </Modal>
  );
}

const statusTextMap: { [key in TransactionStatus]: string } = {
  error: 'ERROR',
  progress: 'IN PROGRESS',
  success: 'SUCCESS',
};
