import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateEmail } from '../../../../utils/validation';
import { setValidatorEmail } from '../../../validator/slice';
import { StyledButton } from '../../common';
import { Content, Input, InputBlock, InputWrapper, Label, LabelRow, SubmitWrapper, ValidationText } from './common';

export function EmailStep() {
  const [email, setEmail] = useState('');
  const [validation, setValidation] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleEmailChange = useCallback(
    (evt) => {
      setEmail(evt.target.value);
      if (validation) {
        setValidation(undefined);
      }
    },
    [validation],
  );

  const handleSubmit = useCallback(() => {
    const val = email.trim();
    const valid = validateEmail(val);
    if (!valid) {
      setValidation('Invalid e-mail');
      return;
    }

    setSubmitting(true);
    dispatch(setValidatorEmail({ email: val }));
  }, [email, dispatch]);

  return (
    <Content>
      <InputBlock>
        <LabelRow>
          <Label>Your e-mail</Label>
          {validation && <ValidationText>{validation}</ValidationText>}
        </LabelRow>
        <InputWrapper>
          <Input $error={!!validation} value={email} onChange={handleEmailChange} />
        </InputWrapper>
      </InputBlock>
      <SubmitWrapper>
        <StyledButton $sm onClick={handleSubmit} disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </StyledButton>
      </SubmitWrapper>
    </Content>
  );
}
