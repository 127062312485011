import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DiscordLogo } from '../../assets/img/discrod-logo.svg';
import { ReactComponent as FooterLogo } from '../../assets/img/footer-logo.svg';
import { ReactComponent as GithubLogo } from '../../assets/img/github-logo.svg';
import { ReactComponent as LinkedinLogo } from '../../assets/img/linkedin-logo.svg';
import { ReactComponent as TelegramLogo } from '../../assets/img/telegram-logo.svg';
import { ReactComponent as TwitterLogo } from '../../assets/img/twitter-logo.svg';
import { WidthWrapper } from './common';
import { responsive } from './theme';

const PrivacyTermsLink = styled.a(({ theme }) => ({
  color: theme.text.primary,
  margin: '0 1rem',
}));

const Copyright = styled.div({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: '1rem',
  },
});

const ContactsWrapper = styled.div({
  display: 'flex',
  '& > *:not(:last-child)': {
    marginRight: '1rem',
  },
  a: {
    svg: {
      width: 20,
      height: 20,
    },
  },
});

const FooterSection = styled.div<{ $greyed?: boolean }>(({ theme, $greyed }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '13px',
  padding: $greyed ? '2rem 0' : '2rem 0 0 0',
  margin: $greyed ? 'auto 0 0 0' : '2rem 0',
  borderTop: `0.5px solid ${theme.border.secondary}`,
  color: theme.text.primary,
  '& svg path': {
    fill: theme.text.primary,
  },
  [responsive.sm]: {
    flexDirection: 'column',
    [`${ContactsWrapper}`]: {
      order: 1,
      marginBottom: '1rem',
    },
    [`${PrivacyTermsLink}`]: {
      order: 2,
      marginBottom: '1rem',
    },
    [`${Copyright}`]: {
      order: 3,
    },
  },
}));

interface FooterProps {
  greyed?: boolean;
}

export function Footer({ greyed }: FooterProps) {
  const currentYear = new Date().getFullYear();

  const FooterContent = (
    <FooterSection $greyed={greyed}>
      <Copyright>
        <FooterLogo />
        Copyright © {currentYear}
      </Copyright>
      <PrivacyTermsLink href="https://www.synternet.com/privacy-policy" target="_blank" rel="noopener">
        Privacy &amp; Terms
      </PrivacyTermsLink>
      <ContactsWrapper>
        <a href="https://github.com/synternet" target="_blank" rel="noreferrer noopener">
          <GithubLogo />
        </a>
        <a href="https://discord.gg/Ze7Kswye8B" target="_blank" rel="noreferrer noopener">
          <DiscordLogo />
        </a>
        <a href="https://t.me/Synternet" target="_blank" rel="noreferrer noopener">
          <TelegramLogo />
        </a>
        <a href="https://www.linkedin.com/company/synternet" target="_blank" rel="noreferrer noopener">
          <LinkedinLogo />
        </a>
        <a href="https://twitter.com/synternet_com" target="_blank" rel="noreferrer noopener">
          <TwitterLogo />
        </a>
      </ContactsWrapper>
    </FooterSection>
  );

  return greyed ? FooterContent : <WidthWrapper>{FooterContent}</WidthWrapper>;
}
