import React from 'react';
import { useMainParams } from '../router/routes';
import { ClaimTab } from './ClaimTab';
import { OverviewTab } from './OverviewTab';
import { StakeTab } from './StakeTab';
import { UnstakeTab } from './UnstakeTab';

export function NominatorTabs() {
  const { tab } = useMainParams();

  return (
    <>
      {tab === 'overview' && <OverviewTab />}
      {tab === 'stake' && <StakeTab />}
      {tab === 'unstake' && <UnstakeTab />}
      {tab === 'claim' && <ClaimTab />}
    </>
  );
}
