import React from 'react';
import styled from 'styled-components';
import { CloseButton, LargeHeading, ModalContent, ModalWrapper, ModalWrapperBackground, Paragraph } from './common';

const Content = styled.div({
  display: 'flex',
  marginBottom: '2rem',
});

const Description = styled(Paragraph)(({ theme }) => ({
  fontSize: '20px',
  color: '#A2A1A1',
  paddingBottom: '1rem',
  borderBottom: `1px solid ${theme.border.primary}`,
  marginBottom: '2rem',
  marginTop: '0.5rem',
}));

const Background = styled.div({
  position: 'absolute',
  top: '30vh',
  right: 0,
  zIndex: -1,
  left: 0,
  bottom: '30vh',
  opacity: 0.5,
  background:
    'linear-gradient(98.01deg, rgba(48, 7, 84, 0.5) 1.41%, rgba(99, 29, 99, 0.5) 17.8%, rgba(156, 40, 100, 0.5) 32.36%, rgba(206, 72, 67, 0.5) 46.16%, rgba(237, 125, 21, 0.5) 60.72%, rgba(240, 192, 35, 0.5) 74.99% )',
  filter: 'blur(250px)',
});

interface ModalProps {
  title: string;
  description: string;
  children: React.ReactNode;
  onClose?: () => void;
  closeHidden?: boolean;
  maxWidth?: string;
}

export function Modal(props: ModalProps) {
  const { title, description, children, closeHidden, maxWidth, onClose } = props;

  return (
    <ModalWrapper $open>
      <ModalWrapperBackground onClick={onClose} />
      <ModalContent $fullMobile $maxWidth={maxWidth}>
        <LargeHeading>{title}</LargeHeading>
        <Description>{description}</Description>
        <Content>{children}</Content>
        {!closeHidden && <CloseButton onClick={onClose}>Close</CloseButton>}
      </ModalContent>
      <Background />
    </ModalWrapper>
  );
}
