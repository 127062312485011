import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AccountTab = 'Dashboard' | 'Activity';
export type TransactionStatus = 'progress' | 'success' | 'error';

export interface TransactionModal {
  title: string;
  description: string;
  status: TransactionStatus;
  locked?: boolean;
}

export interface MainState {
  userWallet?: {
    address: string;
    networkName: string;
  };
  connectModalVisible?: boolean;
  burgerMenuVisible?: boolean;
  accountTab: AccountTab;
  transactionModal?: TransactionModal;
}

const initialState: MainState = {
  accountTab: 'Dashboard',
};

const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setUserWallet: (state, { payload }: PayloadAction<{ address: string; networkName: string }>) => {
      state.userWallet = {
        address: payload.address,
        networkName: payload.networkName,
      };
    },
    openConnectModal: (state) => {
      state.connectModalVisible = true;
    },
    closeConnectModal: (state) => {
      state.connectModalVisible = false;
    },
    toggleBurgerMenu: (state) => {
      state.burgerMenuVisible = !state.burgerMenuVisible;
    },
    setAccountTab: (state, { payload }: PayloadAction<{ tab: AccountTab }>) => {
      state.accountTab = payload.tab;
    },
    setTransactionModal: (state, { payload }: PayloadAction<{ modal?: TransactionModal }>) => {
      state.transactionModal = payload.modal;
    },
    resetWallet: (state) => {
      state.userWallet = initialState.userWallet;
    },
  },
});

export const mainState = (state: RootState) => state.main;
export const mainReducer = slice.reducer;

export const {
  setUserWallet,
  openConnectModal,
  closeConnectModal,
  toggleBurgerMenu,
  setAccountTab,
  setTransactionModal,
  resetWallet,
} = slice.actions;

export const connectMetaMask = createAction(`${slice.name}/connectMetaMask`);
export const accountsChanged = createAction<{ accounts: string[] }>(`${slice.name}/accountsChanged`);
export const resetUserInfo = createAction(`${slice.name}/resetUserInfo`);
