import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bigParse, calculateAvailableAmount, toFixed } from '../../utils/calculations';
import { formatNumber, formatThousand } from '../../utils/numbers';
import { validateStake } from '../../utils/validation';
import {
  AvailableStakeText,
  CenteredInfoText,
  LargeHeading,
  StakeAmount,
  StakeAmountLabel,
  StakeError,
  StakeInput,
  StakeLabel,
  StakingTab,
  StakingWindow,
  StyledButton,
  ValueHeading,
} from '../main/common';
import { ConfirmationBox, StakeImage } from '../main/MainTabs/common';
import { mainState } from '../main/slice';
import { StakeTabStats } from '../main/StakeTabStats';
import { TooltipIcon } from '../main/TooltipIcon';
import { approve, nominatorState, stake } from './slice';

export function StakeTab() {
  const { userWallet } = useSelector(mainState);
  const { globalInfo, userBalances, approving, transactions } = useSelector(nominatorState);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');
  const allowance = userBalances?.allowance || '0';
  const requiresConfirmation = allowance === '0';

  const stakeTransactionInProgress = useMemo(
    () => !!transactions.find((x) => x.action === 'stake' && x.pending),
    [transactions],
  );

  const availableAmount = useMemo(() => {
    if (!userBalances || !globalInfo) {
      return '0';
    }

    return calculateAvailableAmount({
      total: userBalances.userBalance,
      limit: globalInfo?.stake_size_limit_max,
      balance: userBalances.userNominatorBalance,
    });
  }, [globalInfo, userBalances]);

  const error = useMemo(() => {
    return validateStake({
      input: amount,
      userBalance: bigParse(userBalances?.userBalance || '0'),
      availableAmount,
    });
  }, [amount, userBalances?.userBalance, availableAmount]);

  const stakeEnabled = !error && amount.length !== 0 && !stakeTransactionInProgress;

  const handleChangeAmount = useCallback((evt) => {
    const val = evt.target.value;
    setAmount(val);
  }, []);

  const handleStake = useCallback(() => {
    if (!stakeEnabled) {
      return;
    }

    dispatch(stake({ amount }));
    setAmount('');
  }, [amount, stakeEnabled, dispatch]);

  const handleApprove = useCallback(() => {
    if (!userWallet) {
      return;
    }

    dispatch(approve());
  }, [userWallet, dispatch]);

  const handleAmountClick = useCallback(() => {
    if (availableAmount === '0') {
      return;
    }

    handleChangeAmount({ target: { value: availableAmount } });
  }, [availableAmount, handleChangeAmount]);

  return (
    <StakingTab>
      <StakingWindow>
        <TooltipIcon
          variant="absolute"
          text="The Nominator Smart Contract needs to make sure your wallet and the NOIA tokens in it are legitimate. After successful confirmation, you will be able to stake your NOIA."
        />

        <StakeImage />
        <LargeHeading>Stake NOIA</LargeHeading>
        {!requiresConfirmation && (
          <>
            <StakeInput $error={!!error}>
              <StakeLabel>
                <StakeAmountLabel>Amount:</StakeAmountLabel>
                {error && <StakeError>{error}</StakeError>}
              </StakeLabel>
              <input type="text" placeholder="0.0" value={amount} onChange={handleChangeAmount} />
              <ValueHeading>NOIA</ValueHeading>
            </StakeInput>
            <AvailableStakeText>
              Available to stake:
              <StakeAmount onClick={handleAmountClick}>
                {formatNumber(toFixed(availableAmount, 18), formatThousand)} NOIA
              </StakeAmount>
            </AvailableStakeText>
            <StyledButton $disabled={!stakeEnabled} onClick={handleStake}>
              {stakeTransactionInProgress ? 'Staking...' : 'Stake'}
            </StyledButton>
          </>
        )}
        {requiresConfirmation && !approving && (
          <ConfirmationBox>
            <CenteredInfoText>First, you need to approve the Nominator Contract</CenteredInfoText>
            <StyledButton $disabled={!userWallet} onClick={handleApprove}>
              Approve
            </StyledButton>
          </ConfirmationBox>
        )}
        {requiresConfirmation && approving && (
          <ConfirmationBox>
            <CenteredInfoText>
              Your approval transaction is in a blockchain.
              <br />
              Please hold on until it’s completed and you’re approved.
            </CenteredInfoText>
            <StyledButton $disabled>Approval pending...</StyledButton>
          </ConfirmationBox>
        )}
      </StakingWindow>
      <StakeTabStats
        items={[
          {
            label: 'Your current stake:',
            number: formatNumber(toFixed(bigParse(userBalances?.userNominatorBalance || '0')), formatThousand),
            value: 'NOIA',
          },
          {
            label: 'Nominator stake limit:',
            number: formatNumber(globalInfo?.stake_size_limit_max, formatThousand),
            value: 'NOIA',
            tooltip:
              'This is the maximum amount of tokens per one Nominator. You can stake more if you wish but your reward will not be affected.',
          },
        ]}
      />
    </StakingTab>
  );
}
