export const breakpoints = {
  xs: '374px',
  sm: '767px',
  md: '1023px',
  lg: '1279px',
  xl: '1439px',
};

export const responsive = {
  xs: `@media (max-width: ${breakpoints.xs})`,
  sm: `@media (max-width: ${breakpoints.sm})`,
  md: `@media (max-width: ${breakpoints.md})`,
  lg: `@media (max-width: ${breakpoints.lg})`,
  xl: `@media (max-width: ${breakpoints.xl})`,
};
