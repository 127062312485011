import { Tab, Tabs } from '@material-ui/core';
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { responsive } from './theme';

interface SwitchProps {
  values: string[] | number[] | ReactNode[];
  disabledValues?: string[];
  activeKey?: number | boolean;
  initialKey?: number;
  onChange?: (value: number) => void;
  vertical?: boolean;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  changeTimeout?: number;
  edges?: boolean;
  greyMobile?: boolean;
  borderColor?: string;
  tabWidth?: string;
  noResponsive?: boolean;
}

const StyledTabs = styled(Tabs)<{ $borderColor?: string }>(({ theme, $borderColor }) => ({
  '&.MuiTabs-root': {
    backgroundColor: theme.background.secondary,
    flexShrink: 0,
    zIndex: 1,
    padding: '0',
    borderRadius: '30px',
    border: $borderColor ? `1px solid ${$borderColor}` : `1px solid ${theme.border.secondary}`,
    minHeight: 'initial',
    '& .MuiTabs-fixed': {
      overflow: 'visible !important',
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      zIndex: -1,
      transitionDuration: '500ms',
      '& > span': {
        width: '100%',
        height: '100%',
        borderRadius: '30px',
        backgroundColor: theme.switch.indicator.background,
      },
    },
  },
}));

const StyledTab = styled(Tab)<{
  $edgeRight?: boolean;
  $greyMobile?: boolean;
  $tabWidth?: string;
  $noResponsive?: boolean;
  $disabled?: boolean;
}>(({ theme, $edgeRight, $greyMobile, $tabWidth, $noResponsive, $disabled }) => {
  const responsiveStyles = $noResponsive
    ? {}
    : {
        [responsive.sm]: {
          fontSize: '14px',
          minWidth: '100px !important',
          height: '30px',
          '&:not(.Mui-selected)': {
            color: $greyMobile ? '#B2B1B5' : 'auto',
            '&:hover': {
              color: '#111',
            },
          },
        },
      };

  return {
    '&.MuiTab-root': {
      height: '40px',
      minHeight: 'auto',
      minWidth: $tabWidth ? `${$tabWidth} !important` : '120px !important',
      lineHeight: '1.1rem',
      textTransform: 'none',
      fontFamily: 'Foundersgrotesk, sans-serif',
      backgroundColor: 'transparent',
      color: $disabled ? theme.text.disabled : theme.text.secondary,
      fontWeight: 'normal',
      fontSize: '18px',
      transition: 'all 0.5s ease-in-out',
      letterSpacing: 'initial',
      position: 'relative',
      cursor: $disabled ? 'default' : 'pointer',
      '&:after': {
        content: '""',
        borderRight: '1px solid transparent',
        background: 'transparent',
        transition: 'all 0.5s ease-in-out',
        position: 'absolute',
        top: '10px',
        right: 0,
        bottom: '10px',
      },
      '& > span': {
        marginTop: '-1px',
      },
      '&:hover': {
        opacity: 1,
      },
      '&.Mui-selected': {
        color: '#111111',
        fontWeight: 500,
      },
      '&:not(.Mui-selected)': {
        opacity: 1,
        '&:after': {
          borderRightColor: $edgeRight ? theme.border.secondary : 'transparent',
        },
      },
      ...responsiveStyles,
    },
  };
});

export const Switch = ({
  values,
  disabledValues,
  onChange,
  vertical,
  variant = 'fullWidth',
  activeKey,
  initialKey = 0,
  changeTimeout,
  edges,
  greyMobile,
  borderColor,
  tabWidth,
  noResponsive,
}: SwitchProps) => {
  const [value, setValue] = useState<number | boolean>(initialKey);
  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      if (disabledValues && disabledValues.includes(values[value] as string)) {
        return;
      }

      const LinkOverride = values[value] as ReactElement;
      const link = LinkOverride && LinkOverride.props ? LinkOverride.props.href : undefined;
      const isLinkInternal = link ? link.charAt(0) === '/' : true;
      const moveToLink = () => {
        if (onChange) onChange(value);
      };

      if (isLinkInternal) {
        event?.preventDefault();
        setValue(value);
      }

      if (changeTimeout) setTimeout(() => moveToLink(), changeTimeout);
      else moveToLink();
    },
    [onChange, changeTimeout, values, disabledValues],
  );

  useEffect(() => {
    if (activeKey !== undefined) {
      setValue(activeKey);
    }
  }, [activeKey]);

  const onSwitchChange = useCallback(
    (e: React.ChangeEvent<unknown>, value: number) => {
      handleSwitchChange(e, value);
    },
    [handleSwitchChange],
  );

  return (
    <StyledTabs
      onChange={onSwitchChange}
      value={value}
      TabIndicatorProps={{ children: <span /> }}
      orientation={vertical ? 'vertical' : 'horizontal'}
      variant={variant}
      $borderColor={borderColor}
    >
      {values.map((value: string | number | ReactNode, index: number) => {
        return (
          <StyledTab
            key={index}
            label={value}
            value={index}
            disableRipple
            $edgeRight={edges && index !== activeKey && index !== values.length - 1 && index + 1 !== activeKey}
            $greyMobile={greyMobile}
            $tabWidth={tabWidth}
            $noResponsive={noResponsive}
            $disabled={disabledValues && disabledValues.includes(value as string)}
          />
        );
      })}
    </StyledTabs>
  );
};
