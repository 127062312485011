import { ethers } from 'ethers';
import Web3 from 'web3';
import { ContractInfo } from '../types';

let ethersProvider: any;
let contractReadWrite: ethers.Contract;
let validatorReadWrite: ethers.Contract;
let nominatorReadWrite: ethers.Contract;
let validatorAddress: string;
let nominatorAddress: string;

export function initializeContracts({
  contract,
  validator,
  nominator,
}: {
  contract: ContractInfo;
  validator: ContractInfo;
  nominator: ContractInfo;
}) {
  validatorAddress = validator.address;
  nominatorAddress = nominator.address;

  window.web3 = new Web3(window.ethereum);
  ethersProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');

  const contractAbiJson = JSON.parse(contract.abi);
  contractReadWrite = new ethers.Contract(contract.address, contractAbiJson, ethersProvider.getSigner());

  const validatorAbiJson = JSON.parse(validator.abi);
  validatorReadWrite = new ethers.Contract(validator.address, validatorAbiJson, ethersProvider.getSigner());

  const nominatorAbiJson = JSON.parse(nominator.abi);
  nominatorReadWrite = new ethers.Contract(nominator.address, nominatorAbiJson, ethersProvider.getSigner());
}

export function getContracts() {
  return { validatorReadWrite, nominatorReadWrite, contractReadWrite, validatorAddress, nominatorAddress };
}
