import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LogoImage } from '../main/common';

const Iframe = styled.iframe({
  flex: 1,
  border: 'none',
});

const StyledLogo = styled(LogoImage)({
  margin: '3rem',
});

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  background: '#f8f9fa',
});

const LogoWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export function Kyc() {
  const { id } = useParams<{ id?: string }>();
  return (
    <Wrapper>
      <LogoWrapper>
        <StyledLogo src="/images/syntropy-logo-black.svg" loading="lazy" alt="" />
      </LogoWrapper>
      <Iframe id="kyc" allowFullScreen src={`https://ui.idenfy.com/?authToken=${id}`} allow="camera"></Iframe>
    </Wrapper>
  );
}
