import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Kyc } from './features/kyc';
import { Main } from './features/main';
import { kycRoute, mainRoute } from './features/router/routes';
import { history, store } from './store';
import { stakingTheme, GlobalStyle } from './theme';

export function App() {
  return (
    <ThemeProvider theme={stakingTheme}>
      <GlobalStyle />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path={kycRoute}>
              <Kyc />
            </Route>
            <Route path={mainRoute}>
              <Main />
            </Route>
          </Switch>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  );
}
