import MetaMaskOnboarding from '@metamask/onboarding';
import { ContractInfo, NominatorBalances, ValidatorBalances } from '../types';
import { getDecimalAount, nominatorApproveAmount, validatorApproveAmount } from './calculations';
import { getContracts, initializeContracts } from './contracts';

interface TransactionConfirmationResponse {
  transactionHash: string;
}

interface TransactionCreationResponse {
  wait: TransactionWaitConfirmation;
  hash: string;
}

export type TransactionWaitConfirmation = () => Promise<TransactionConfirmationResponse>;

export function metaMaskInstalled() {
  return MetaMaskOnboarding.isMetaMaskInstalled();
}

export function initializeMetaMask({
  contract,
  validator,
  nominator,
}: {
  contract: ContractInfo;
  validator: ContractInfo;
  nominator: ContractInfo;
}) {
  if (!metaMaskInstalled()) {
    return;
  }

  initializeContracts({ contract, validator, nominator });
}

export async function checkConnectedAccounts(): Promise<string[]> {
  const accounts = await window.ethereum.request({
    method: 'eth_accounts',
  });

  return accounts;
}

export async function connect(): Promise<string[]> {
  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });

  return accounts;
}

const chains: any = {
  '0x1': 'Ethereum Mainnet',
  '0x3': 'Ropsten',
  '0x4': 'Rinkeby',
  '0x5': 'Goerli',
  '0x2a': 'Kovan',
};

export async function fetchNetworkName(): Promise<string> {
  const chainId = await window.ethereum.request({
    method: 'eth_chainId',
  });

  return getNetworkName(chainId);
}

export async function checkValidatorBalance(userAddress: string): Promise<ValidatorBalances> {
  const { contractReadWrite, validatorReadWrite, validatorAddress } = getContracts();
  const userBalance = await contractReadWrite.balanceOf(userAddress);
  const userStake = await validatorReadWrite.stakeOf(userAddress);
  const userValidatorBalance = await validatorReadWrite.balances(userAddress);
  const allowance = await contractReadWrite.allowance(userAddress, validatorAddress);
  const totalPayouts = await validatorReadWrite.totalPayoutsFor(userAddress);

  return {
    userBalance: userBalance.toString(),
    userStake: userStake.toString(),
    userValidatorBalance: userValidatorBalance.toString(),
    allowance: allowance.toString(),
    totalPayouts: totalPayouts.toString(),
  };
}

export async function checkNominatorBalance(userAddress: string): Promise<NominatorBalances> {
  const { contractReadWrite, nominatorReadWrite, nominatorAddress } = getContracts();
  const userBalance = await contractReadWrite.balanceOf(userAddress);
  const userStake = await nominatorReadWrite.stakeOf(userAddress);
  const userNominatorBalance = await nominatorReadWrite.balances(userAddress);
  const allowance = await contractReadWrite.allowance(userAddress, nominatorAddress);
  const totalPayouts = await nominatorReadWrite.totalPayoutsFor(userAddress);

  return {
    userBalance: userBalance.toString(),
    userStake: userStake.toString(),
    userNominatorBalance: userNominatorBalance.toString(),
    allowance: allowance.toString(),
    totalPayouts: totalPayouts.toString(),
  };
}

export async function approveValidator(): Promise<TransactionCreationResponse> {
  const { contractReadWrite, validatorAddress } = getContracts();
  return await contractReadWrite.approve(validatorAddress, validatorApproveAmount);
}

export async function approveNominator(): Promise<TransactionCreationResponse> {
  const { contractReadWrite, nominatorAddress } = getContracts();
  return await contractReadWrite.approve(nominatorAddress, nominatorApproveAmount);
}

export async function depositToValidator(amount: string): Promise<TransactionCreationResponse> {
  const { validatorReadWrite } = getContracts();
  return await validatorReadWrite.deposit(getDecimalAount(amount));
}

export async function depositToNominator(amount: string): Promise<TransactionCreationResponse> {
  const { nominatorReadWrite } = getContracts();
  return await nominatorReadWrite.deposit(getDecimalAount(amount));
}

export async function withdrawFromValidator(amount: string): Promise<TransactionCreationResponse> {
  const { validatorReadWrite } = getContracts();
  return await validatorReadWrite.withdraw(getDecimalAount(amount));
}

export async function requestWithdrawFromNominator(amount: string): Promise<TransactionCreationResponse> {
  const { nominatorReadWrite } = getContracts();
  return await nominatorReadWrite.requestWithdraw(getDecimalAount(amount));
}

export async function withdrawFromNominator(address: string, amount: string): Promise<TransactionCreationResponse> {
  const { nominatorReadWrite } = getContracts();
  return await nominatorReadWrite.withdraw(address, getDecimalAount(amount));
}

export async function getNominatorWithdrawalRequests(
  address: string,
  block: number,
): Promise<{ amount: string; timestamp: number }> {
  const { nominatorReadWrite } = getContracts();
  const result = await nominatorReadWrite.withdrawalRequests(address, block);
  return { amount: result.amount.toString(), timestamp: result.timestamp };
}

export interface ClaimRewardProps {
  recipient: string;
  totalEarned: string;
  blockNumber: number;
  proof: string[];
}

export async function claimRewardFromValidator(props: ClaimRewardProps): Promise<TransactionCreationResponse> {
  const { validatorReadWrite } = getContracts();
  return await validatorReadWrite.withdrawReward(props.recipient, props.totalEarned, props.blockNumber, props.proof);
}

export async function claimRewardFromNominator(props: ClaimRewardProps): Promise<TransactionCreationResponse> {
  const { nominatorReadWrite } = getContracts();
  return await nominatorReadWrite.withdrawReward(props.recipient, props.totalEarned, props.blockNumber, props.proof);
}

export async function claimRewardFromValidatorTest(props: ClaimRewardProps): Promise<TransactionCreationResponse> {
  const { validatorReadWrite } = getContracts();
  return await validatorReadWrite.isValidProof(props.recipient, props.totalEarned, props.blockNumber, props.proof);
}

export function subscribeToAccountsChanged(onChange: (accounts: string[]) => void) {
  window.ethereum.on('accountsChanged', (newAccounts: string[]) => {
    onChange(newAccounts);
  });
}

export function subscribeToNetworkChanged(onChange: (networkName: string) => void) {
  window.ethereum.on('chainChanged', (chainId: string) => {
    const name = getNetworkName(chainId);
    onChange(name);
  });
}

function getNetworkName(chainId: string) {
  return chains[chainId] || 'Unknown';
}

export function signWallet(message: string, address: string): Promise<string> {
  return window.web3.eth.personal.sign(message, address, undefined as any);
}
