import { LOCATION_CHANGE } from 'connected-react-router';
import { call, delay, fork, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
  createVerificationRequest,
  CreateVerificationRequestResponse,
  getGlobalNominatorInfo,
  getNominatorHistory,
  getNominatorUserStake,
  validateSignedRequest,
  ValidateSignedRequestResponse,
} from '../../api';
import { Transaction } from '../../types';
import {
  bigParse,
  calculateUnclaimedRewards,
  getNominatorBondedUnstakeTotal,
  getNominatorEnabledUnstakeTotal,
  getNominatorUnstakeTotal,
  toFixed,
} from '../../utils/calculations';
import { formatTransactionDate, fromUnixDate } from '../../utils/date';
import { mapHistoryItem, mapNominatorStakeInfo, mapUnstakeItem, recomputeUnstakeItem } from '../../utils/map';
import {
  approveNominator,
  checkNominatorBalance,
  claimRewardFromNominator,
  ClaimRewardProps,
  depositToNominator,
  getNominatorWithdrawalRequests,
  requestWithdrawFromNominator,
  signWallet,
  TransactionWaitConfirmation,
  withdrawFromNominator,
} from '../../utils/metamask';
import { formatThousand } from '../../utils/numbers';
import { selectRouteParams } from '../../utils/saga';
import { showError } from '../main/sagas';
import {
  mainState,
  resetUserInfo,
  setAccountTab,
  setTransactionModal,
  setUserWallet,
  TransactionModal,
} from '../main/slice';
import { getKycRoute, mainRoute, MainRouteParams } from '../router/routes';
import {
  approve,
  claim,
  kycValidate,
  nominatorState,
  NominatorUnstakeItem,
  recomputeUnstakeInfo,
  refreshUnstakeItems,
  requestUnstake,
  resetNominatorUserInfo,
  setApproving,
  setGlobalInfo,
  setKycError,
  setKycLink,
  setKycModalStep,
  setTransactions,
  setUnstakeItems,
  setUserBalances,
  setUserStake,
  stake,
  unstake,
} from './slice';

const unstakeTickerMs = 20000;

function* initialize() {
  yield* fork(fetchGlobalInfo);
}

function* fetchGlobalInfo() {
  try {
    const globalInfo = yield* call(getGlobalNominatorInfo);
    yield* put(setGlobalInfo({ globalInfo }));
  } catch (err) {
    yield* call(showError, err);
  }
}

function* watchRefreshUnstakeItems() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  const items: NominatorUnstakeItem[] = [];
  try {
    for (let i = 0; i < 1000; i++) {
      const { amount, timestamp } = yield* call(getNominatorWithdrawalRequests, userWallet.address, i);
      items.push({
        amount: bigParse(amount),
        date: fromUnixDate(timestamp).toISOString(),
      });
    }
  } catch (err) {
    // finished loading
  }

  items.sort((a, b) => {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }

    return 0;
  });

  const mapped = items.map(mapUnstakeItem);
  const total = getNominatorUnstakeTotal(mapped);
  const enabled = getNominatorEnabledUnstakeTotal(mapped);
  const bonded = getNominatorBondedUnstakeTotal(mapped);
  yield* put(setUnstakeItems({ items: mapped, total, enabled, bonded }));
}

function* watchSetUserWallet() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  yield* fork(fetchHistory);
  yield* put(refreshUnstakeItems());

  try {
    const userBalances = yield* call(checkNominatorBalance, userWallet.address);
    yield* put(setUserBalances({ userBalances }));
  } catch (err) {
    yield* call(showError, err);
  }

  try {
    const response = yield* call(getNominatorUserStake, userWallet.address);
    const userStake = mapNominatorStakeInfo(response);
    yield* put(setUserStake({ userStake }));
  } catch (err: any) {
    if (err.response.status !== 404) {
      yield* call(showError, err);
    }
  }
}

function* fetchHistory() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  try {
    const items = yield* call(getNominatorHistory, userWallet.address);
    const mapped: Transaction[] = items.map(mapHistoryItem);
    const { transactions } = yield* select(nominatorState);
    const tempTransactions = transactions.filter((x) => !mapped.some((y) => y.hash === x.hash));
    const updated = [...tempTransactions, ...mapped];

    yield* put(setTransactions({ transactions: updated }));
  } catch (err: any) {
    if (err?.response?.status !== 404) {
      yield* call(showError, err);
    }
  }
}

function* watchApprove() {
  const modal: TransactionModal = {
    title: `Approving nominator wallet`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const response = yield* call(approveNominator);
    const newTransaction: Transaction = {
      amount: '0',
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'approve',
      contractType: 'nominator',
    };

    const { transactions } = yield* select(nominatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* put(setApproving({ approving: true }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Nominator wallet successfully approved`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to approve nominator wallet`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* waitForTransactionConfirmation(wait: TransactionWaitConfirmation) {
  try {
    const { transactionHash } = yield* call(wait);
    const { userWallet } = yield* select(mainState);
    const { transactions } = yield* select(nominatorState);
    const existingTransaction = transactions.find((x) => x.hash === transactionHash);
    if (existingTransaction) {
      const updatedTransactions = transactions.map((x) => (x.hash === transactionHash ? { ...x, pending: false } : x));
      yield* put(setTransactions({ transactions: updatedTransactions }));

      if (userWallet) {
        const userBalances = yield* call(checkNominatorBalance, userWallet.address);
        yield* put(setUserBalances({ userBalances }));

        if (existingTransaction.action === 'approve') {
          yield* put(setApproving({ approving: false }));
        }

        if (existingTransaction.action === 'request') {
          yield* put(refreshUnstakeItems());
        }

        if (existingTransaction.action === 'unstake') {
          yield* put(refreshUnstakeItems());
        }
      }
    }
  } catch (err: any) {
    if (err.reason && err.hash) {
      const { transactions } = yield* select(nominatorState);
      const existingTransaction = transactions.find((x) => x.hash === err.hash);
      if (existingTransaction) {
        const updatedTransactions = transactions.map((x) =>
          x.hash === err.hash ? { ...x, failReason: err.reason, pending: undefined } : x,
        );
        yield* put(setTransactions({ transactions: updatedTransactions }));
      }
    } else {
      yield* call(showError, err);
    }
  }
}

function* watchStake({ payload }: ReturnType<typeof stake>) {
  const amount = formatThousand(payload.amount);

  const modal: TransactionModal = {
    title: `Staking ${amount} NOIA`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const response = yield* call(depositToNominator, payload.amount);
    const newTransaction: Transaction = {
      amount: payload.amount,
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'stake',
      contractType: 'nominator',
    };

    const { transactions } = yield* select(nominatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Successfully staked ${amount} NOIA`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to stake ${amount} NOIA`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* watchRequestUnstake({ payload }: ReturnType<typeof stake>) {
  const amount = formatThousand(payload.amount);

  const modal: TransactionModal = {
    title: `Requesting to unstake ${amount} NOIA`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const response = yield* call(requestWithdrawFromNominator, payload.amount);
    const newTransaction: Transaction = {
      amount: payload.amount,
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'request',
      contractType: 'nominator',
    };

    const { transactions } = yield* select(nominatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Successfully requested to unstake ${amount} NOIA`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to request unstake ${amount} NOIA`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* watchUnstake() {
  const { unstakeEnabled } = yield* select(nominatorState);
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  const amount = formatThousand(unstakeEnabled);

  const modal: TransactionModal = {
    title: `Unstaking ${amount} NOIA`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const response = yield* call(withdrawFromNominator, userWallet.address, unstakeEnabled);
    const newTransaction: Transaction = {
      amount: unstakeEnabled,
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'unstake',
      contractType: 'nominator',
    };

    const { transactions } = yield* select(nominatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Successfully unstaked ${amount} NOIA`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to unstake ${amount} NOIA`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* watchClaim() {
  const { userWallet } = yield* select(mainState);
  const { userStake, userBalances } = yield* select(nominatorState);

  if (!userStake || !userBalances || !userWallet) {
    return;
  }

  const { claimProof } = userStake;
  if (!claimProof) {
    return;
  }

  const amountBig = calculateUnclaimedRewards(claimProof.reward, userBalances.totalPayouts);
  const amount = toFixed(bigParse(amountBig));

  const modal: TransactionModal = {
    title: `Claiming ${amount} NOIA`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const request: ClaimRewardProps = {
      recipient: userWallet.address,
      totalEarned: claimProof.reward,
      blockNumber: claimProof.block,
      proof: claimProof.proof,
    };

    const response = yield* call(claimRewardFromNominator, request);
    const newTransaction: Transaction = {
      amount: amount,
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'claim',
      contractType: 'nominator',
    };

    const { transactions } = yield* select(nominatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Successfully claimed ${amount} NOIA`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to claim ${amount} NOIA`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* watchResetUserInfo() {
  yield* put(resetNominatorUserInfo());
}

function* watchKycValidate() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  let nonceResponse: CreateVerificationRequestResponse;

  try {
    nonceResponse = yield* call(createVerificationRequest, {
      operationType: 'NOMINATOR_KYC',
      wallet: userWallet.address,
    });
  } catch (err) {
    yield* put(setKycError({ error: 'Failed to fetch validation request. Try again.' }));
    return;
  }

  yield* put(setKycModalStep({ step: 'metamask-pending' }));

  let signResult;
  try {
    signResult = yield* call(signWallet, nonceResponse.messageForVerification, userWallet.address);
  } catch (err) {
    yield* put(setKycError({ error: 'Failed to sign with MetaMask. Try again.' }));
    return;
  }

  let kycResponse: ValidateSignedRequestResponse;
  try {
    kycResponse = yield* call(validateSignedRequest, { nonce: nonceResponse.nonce, hash: signResult });
    if (!kycResponse.validated) {
      yield* put(setKycError({ error: 'Signature is not valid. Try again.' }));
      return;
    }
  } catch (err) {
    yield* put(setKycError({ error: 'Failed to validate signed request. Try again.' }));
    return;
  }

  const link = location.origin + getKycRoute(kycResponse.kyc_token || '');
  yield* put(setKycLink({ link }));
  yield* put(setKycModalStep({ step: 'kyc-link' }));
}

function* watchLocationChange() {
  const params = yield* selectRouteParams<MainRouteParams>(mainRoute);
  if (!params || params.scope !== 'nominator' || params.tab !== 'unstake') {
    return;
  }

  yield* fork(unstakeInfoTicker);
}

function* unstakeInfoTicker() {
  yield* put(recomputeUnstakeInfo());
  while (true) {
    yield* delay(unstakeTickerMs);
    yield* put(recomputeUnstakeInfo());
  }
}

function* watchRecomputeUnstakeInfo() {
  const { unstakeItems } = yield* select(nominatorState);
  if (unstakeItems.length === 0) {
    return;
  }

  const mapped = unstakeItems.map(recomputeUnstakeItem);
  const total = getNominatorUnstakeTotal(mapped);
  const enabled = getNominatorEnabledUnstakeTotal(mapped);
  const bonded = getNominatorBondedUnstakeTotal(mapped);
  yield* put(setUnstakeItems({ items: mapped, total, enabled, bonded }));
}

export function* nominatorSagas() {
  yield* takeLatest(setUserWallet, watchSetUserWallet);
  yield* takeLatest(approve, watchApprove);
  yield* takeEvery(stake, watchStake);
  yield* takeEvery(requestUnstake, watchRequestUnstake);
  yield* takeLatest(refreshUnstakeItems, watchRefreshUnstakeItems);
  yield* takeEvery(unstake, watchUnstake);
  yield* takeEvery(claim, watchClaim);
  yield* takeLatest(resetUserInfo, watchResetUserInfo);
  yield* takeLatest(kycValidate, watchKycValidate);
  yield* takeLatest(recomputeUnstakeInfo, watchRecomputeUnstakeInfo);
  yield* takeLatest(LOCATION_CHANGE, watchLocationChange);
  yield* initialize();
}
