import styled from 'styled-components';
import { responsive } from '../../theme';

export const StakeHeading = styled.div({
  fontSize: '18px',
  textTransform: 'uppercase',
  marginBottom: '0.5rem',
  [responsive.sm]: {
    fontSize: '14px',
    marginBottom: 0,
    marginRight: '2rem',
  },
});

export const StakeBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '33%',
  '& > span': {
    fontWeight: 500,
  },
  [responsive.sm]: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.75rem',
  },
});

export const StakeInfo = styled.div(({ theme }) => ({
  marginTop: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '2rem',
  borderBottom: `1px solid ${theme.border.secondary}`,
  [responsive.sm]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`${StakeBox}:not(:last-child)`]: {
    marginRight: '2rem',
  },
}));

export const AddressLink = styled.a(({ theme }) => ({
  color: theme.text.link,
  textUnderlineOffset: '2px',
  fontSize: '24px',
  display: 'flex',
  alignItems: 'center',
  '& img': {
    marginLeft: '1rem',
  },
  [responsive.sm]: {
    fontSize: '18px',
  },
  '&:hover': {
    opacity: 0.5,
  },
}));

export const Tokens = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'flex-start',
});

export const AddressContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
});
