import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { bigParse, toFixed } from '../../../utils/calculations';
import { formatNumber, formatThousand } from '../../../utils/numbers';
import { openEmailVerificationModal, openValidatorKeyModal, validatorState } from '../../validator/slice';
import { StyledButton } from '../common';
import { CustomTooltip } from '../CopyAddress';
import { AccountDashboardProps, BalanceWrapper, DashboardItem } from './common';
import { EmailVerificationModal } from './EmailVerificationModal';
import { ValidatorKeyModal } from './ValidatorKeyModal';
import { ValidatorStatusBox } from './ValidatorStatusBox';
import { ValidatorWhitelistStatus } from './ValidatorWhitelistStatus';

const Actions = styled.div({
  marginTop: '35px',
  marginBottom: '35px',
  display: 'flex',
  justifyContent: 'center',
});

export function ValidatorDashboard({ styled }: AccountDashboardProps) {
  const { userBalances, userStake } = useSelector(validatorState);
  const dispatch = useDispatch();

  const handleVerifyEmail = useCallback(() => {
    dispatch(openEmailVerificationModal());
  }, [dispatch]);

  const handleGetAccessKey = useCallback(() => {
    dispatch(openValidatorKeyModal());
  }, [dispatch]);

  if (!userBalances) {
    return null;
  }

  return (
    <BalanceWrapper $styled={styled}>
      <Actions>
        {!userStake && (
          <CustomTooltip
            title="E-mail verification is available once your stake is verified by the dashboard. This can take up to 30 minutes."
            arrow
            placement="bottom"
          >
            <StyledButton $disabled>E-mail verification</StyledButton>
          </CustomTooltip>
        )}
        {userStake && !userStake.emailVerified && (
          <StyledButton onClick={handleVerifyEmail}>E-mail verification</StyledButton>
        )}
        {userStake && userStake.emailVerified && (
          <StyledButton onClick={handleGetAccessKey}>Get access key</StyledButton>
        )}
      </Actions>
      <ValidatorWhitelistStatus styled={styled} />
      <ValidatorStatusBox styled={styled} />
      <DashboardItem
        styled={styled}
        title="Wallet token balance:"
        amount={formatNumber(toFixed(bigParse(userBalances.userBalance)), formatThousand)}
      />
      <DashboardItem
        styled={styled}
        title="Staked token balance:"
        amount={formatNumber(toFixed(bigParse(userBalances.userValidatorBalance)), formatThousand)}
      />
      <EmailVerificationModal />
      <ValidatorKeyModal />
    </BalanceWrapper>
  );
}
