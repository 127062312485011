import React, { useCallback } from 'react';
import styled from 'styled-components';
import { KycStep } from '../../types';
import { StyledButton } from '../main/common';
import { Modal } from '../main/Modal';
import { ErrorStep, MetamaskStep } from '../main/wizard';

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
});

const ValidateButton = styled(StyledButton)({
  flexBasis: '246px',
});

const LinkExplanation = styled.div({
  fontSize: '18px',
  textAlign: 'center',
});

const LinkWrapper = styled.div({
  padding: '24px 50px',
  border: '0.5px solid #B7B7CC',
  borderRadius: '10px',
  marginTop: '17px',
  marginBottom: '27px',
  maxWidth: '600px',
});

const KycLink = styled.a(({ theme }) => ({
  fontSize: '18px',
  color: theme.text.link,
  textDecoration: 'none',
  overflow: 'hidden',
  display: 'block',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));

interface KycModalProps {
  step: KycStep;
  error?: string;
  link?: string;
  onClose: () => void;
  onValidate: () => void;
}

export function KycModal(props: KycModalProps) {
  const { step, error, link, onClose, onValidate } = props;

  return (
    <Modal
      title="KYC"
      description="Please go through KYC process in order to validate your wallet."
      maxWidth="698px"
      onClose={onClose}
    >
      {step === 'validate-wallet' && <ValidateStep onValidate={onValidate} />}
      {step === 'metamask-pending' && <MetamaskStep />}
      {step === 'kyc-link' && <KycLinkStep link={link || ''} />}
      {step === 'error' && <ErrorStep text={error} />}
    </Modal>
  );
}

interface ValidateStepProps {
  onValidate: () => void;
}

function ValidateStep({ onValidate }: ValidateStepProps) {
  return (
    <Wrapper>
      <ValidateButton onClick={onValidate}>Validate Wallet</ValidateButton>
    </Wrapper>
  );
}

interface KycLinkStepProps {
  link: string;
}

function KycLinkStep({ link }: KycLinkStepProps) {
  const handleGoToLink = useCallback(() => {
    window.open(link, '_blank');
  }, [link]);

  return (
    <Wrapper>
      <div>
        <LinkExplanation>Your KYC link is ready:</LinkExplanation>
        <LinkWrapper>
          <KycLink href={link} target="_blank">
            {link}
          </KycLink>
        </LinkWrapper>
        <Wrapper>
          <ValidateButton onClick={handleGoToLink}>Go to link</ValidateButton>
        </Wrapper>
      </div>
    </Wrapper>
  );
}
