import { createMatchSelector } from 'connected-react-router';
import { call, select } from 'typed-redux-saga';

function getRouteParams<T>(path: string, state: RootState): T | undefined {
  const matchSelector = createMatchSelector(path);
  const match = matchSelector(state);
  return match ? (match.params as T) : undefined;
}

export function* selectRouteParams<T>(routePath: string): Generator<unknown, T | undefined, any> {
  return yield rawSelectRouteParams<T>(routePath);
}

function* rawSelectRouteParams<T>(routePath: string) {
  const rootState = yield* select();
  const result = yield* call(getRouteParams, routePath, rootState);
  return result as T | undefined;
}
