import { ContractInfo, Environment } from '../types';

export function getApiUrl() {
  return window._env_.API_URL;
}

export function getEnvironment(): Environment {
  return window._env_.ENVIRONMENT;
}

export function getContractVars(): ContractInfo {
  return {
    address: window._env_.CONTRACT_ADDRESS,
    abi: window._env_.CONTRACT_ABI,
  };
}

export function getValidatorVars(): ContractInfo {
  return {
    address: window._env_.VALIDATOR_ADDRESS,
    abi: window._env_.VALIDATOR_ABI,
  };
}

export function getNominatorVars(): ContractInfo {
  return {
    address: window._env_.NOMINATOR_ADDRESS,
    abi: window._env_.NOMINATOR_ABI,
  };
}

export function getTransactionUrl(): string {
  return window._env_.TRANSACTION_URL;
}
