import React from 'react';
import styled from 'styled-components';
import { LargeHeading, Paragraph } from '../../common';
import { responsive } from '../../theme';

const AboutNoia = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '2rem',
  [responsive.sm]: {
    flexWrap: 'wrap',
    '& > div:nth-child(1)': {
      display: 'flex',
      '& img': {
        marginLeft: '0.5rem',
      },
    },
    '& > div:nth-child(2)': {
      margin: '2rem auto 0 auto',
    },
  },
});

const HeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export function About() {
  return (
    <AboutNoia>
      <HeaderWrapper>
        <LargeHeading>About Synternet</LargeHeading>
      </HeaderWrapper>
      <Paragraph>
        Synternet is building a multi-chain data availability layer, providing decentralized and scalable way to access,
        retrieve and interact with real-time and historical on-chain data.
      </Paragraph>
      <Paragraph>
        It is based on a combination of a technologies that includes blockchain, real-time data streams, optimized
        routing, and an economic model that enables and fosters deployment of this architecture.
      </Paragraph>
    </AboutNoia>
  );
}
