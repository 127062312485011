import axios from 'axios';
import { ContractType, ValidatorStatus } from '../types';

interface GlobalInfo {
  annual_yield_current: number;
  stake_rate_current: number;
  stake_rate_progress: number;
  stake_rate_target: number;
  stake_size_current_min: number;
  stake_size_limit_min: number;
  stake_size_limit_max: number;
  stake_size_current_max: number;
}

export interface ValidatorGlobalInfo extends GlobalInfo {
  validators_limit: number;
  validators_current: number;
  validators_waiting: number;
}

export function getGlobalValidatorInfo(): Promise<ValidatorGlobalInfo> {
  return axios.get('/staking-info');
}

export interface ValidatorStakeResponse {
  annual_yield: number;
  current_position: number;
  current_stake: number | string;
  current_total: number | string;
  sum_earned: [];
  total_interest: number | string;
  validator_status: ValidatorStatus;
  validator_text: string;
  kyc_completed: boolean;
  stake_whitelisted: boolean;
  email_verified: boolean;
  claim_proof?: { block: number; proof: string[]; reward: string };
}

export function getValidatorUserStake(address: string): Promise<ValidatorStakeResponse> {
  return axios.get('/get-stake/' + address);
}

export interface HistoryItem {
  block_number: number;
  block_ts: number; // unix timestamp
  contract: ContractType;
  log_idx: number;
  stakeholder: string;
  token_delta: string; // amount with many zeros
  tx_hash: string;
  tx_suffix: 'from' | 'to';
  tx_type: 'deposit' | 'withdraw' | 'claim' | 'request';
}

export function getValidatorHistory(address: string): Promise<HistoryItem[]> {
  return axios.get('/staking-history/validator/' + address);
}

export interface NominatorGlobalInfo extends GlobalInfo {
  nominators_current: number;
}

export function getGlobalNominatorInfo(): Promise<NominatorGlobalInfo> {
  return axios.get('/staking-info-nominator');
}

export interface NominatorStakeResponse {
  current_stake: string;
  current_total: string;
  sum_earned: [];
  total_interest: string;
  kyc_completed: boolean;
  kyc_required?: boolean;
  claim_proof?: { block: number; proof: string[]; reward: string };
}

export function getNominatorUserStake(address: string): Promise<NominatorStakeResponse> {
  return axios.get('/get-stake-nominator/' + address);
}

export function getNominatorHistory(address: string): Promise<HistoryItem[]> {
  return axios.get('/staking-history/nominator/' + address);
}

type SignOperationType = 'NOMINATOR_KYC' | 'VALIDATOR_KYC' | 'VALIDATOR_EMAIL_VERIFY' | 'VALIDATOR_GET_ACCESS_KEY';

interface CreateVerificationRequestRequest {
  operationType: SignOperationType;
  wallet: string;
  email?: string;
}

export interface CreateVerificationRequestResponse {
  nonce: string;
  messageForVerification: string;
}

export function createVerificationRequest(
  req: CreateVerificationRequestRequest,
): Promise<CreateVerificationRequestResponse> {
  return axios.post('/create-verification-request', req);
}

interface ValidateSignedRequestRequest {
  hash: string;
  nonce: string;
}

export interface ValidateSignedRequestResponse {
  kyc_token?: string;
  access_key?: string;
  type: SignOperationType;
  validated: boolean;
}

export function validateSignedRequest(req: ValidateSignedRequestRequest): Promise<ValidateSignedRequestResponse> {
  return axios.post('/validate-signed-request', req);
}

interface GetEmailVerificationNonceRequest {
  wallet: string;
  secret: string;
}

export interface GetEmailVerificationNonceResponse {
  nonce: string;
  messageForVerification: string;
}

export function getEmailVerificationNonce(
  req: GetEmailVerificationNonceRequest,
): Promise<GetEmailVerificationNonceResponse> {
  return axios.post('/get-nonce-from-email-verification', req);
}
