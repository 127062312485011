import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bigParse, calculateUnclaimedRewards, toFixed } from '../../utils/calculations';
import { formatNumber, formatThousand } from '../../utils/numbers';
import {
  AvailableStakeText,
  InputTextLink,
  LargeHeading,
  StakeInput,
  StakingTab,
  StakingWindow,
  StyledButton,
  ValueHeading,
} from '../main/common';
import { FilledStakeImage } from '../main/MainTabs/common';
import { StakeTabStats } from '../main/StakeTabStats';
import { TooltipIcon } from '../main/TooltipIcon';
import { NominatorKycModal } from './NominatorKycModal';
import { claim, nominatorState, openKycModal } from './slice';

export function ClaimTab() {
  const { transactions, userStake, userBalances } = useSelector(nominatorState);
  const dispatch = useDispatch();
  const totalRewards = formatNumber(toFixed(userStake?.totalInterest || '0'), formatThousand);
  let inputValue = calculateUnclaimedRewards(userStake?.claimProof?.reward, userBalances?.totalPayouts);
  if (inputValue === '0') {
    inputValue = '';
  } else {
    inputValue = toFixed(bigParse(inputValue));
  }

  const unclaimed = formatNumber(inputValue === '' ? undefined : inputValue, formatThousand);

  const claimTransactionInProgress = useMemo(
    () => !!transactions.find((x) => x.action === 'claim' && x.pending),
    [transactions],
  );

  const claimEnabled =
    !!inputValue &&
    !claimTransactionInProgress &&
    userStake?.claimProof &&
    (!userStake?.kycRequired || userStake?.kycCompleted);

  const handleClaim = useCallback(() => {
    if (!claimEnabled) {
      return;
    }

    dispatch(claim());
  }, [claimEnabled, dispatch]);

  const kycVisible = userStake?.kycRequired && !userStake?.kycCompleted;

  const handleKycClick = useCallback(() => {
    dispatch(openKycModal());
  }, [dispatch]);

  return (
    <StakingTab>
      <StakingWindow>
        <TooltipIcon
          variant="absolute"
          text="The amount you see here is what you have accumulated so far. By Claiming NOIA, the Nominator Smart Contract will send the amount you see on your screen to your Nominator wallet. We might ask you to submit your KYC if the total amount of accrued reward exceeds a certain amount."
        />
        <FilledStakeImage />
        <LargeHeading>Your Reward</LargeHeading>
        <StakeInput>
          <input type="text" placeholder="0.0" value={inputValue === '0' ? '' : inputValue} disabled />
          <ValueHeading>NOIA</ValueHeading>
        </StakeInput>
        {kycVisible && (
          <AvailableStakeText>
            Claiming NOIA is not yet available until you complete KYC
            <InputTextLink onClick={handleKycClick}>here.</InputTextLink>
          </AvailableStakeText>
        )}
        <StyledButton $disabled={!claimEnabled} onClick={handleClaim}>
          {claimTransactionInProgress ? 'Claiming...' : 'Claim NOIA'}
        </StyledButton>
      </StakingWindow>
      <StakeTabStats
        items={[
          {
            label: 'Your current stake:',
            number: formatNumber(toFixed(bigParse(userBalances?.userNominatorBalance || '0')), formatThousand),
            value: 'NOIA',
          },
          {
            label: 'Your unclaimed rewards:',
            number: unclaimed,
            value: 'NOIA',
          },
          {
            label: 'Your total rewards:',
            number: totalRewards,
            value: 'NOIA',
          },
        ]}
      />
      <NominatorKycModal />
    </StakingTab>
  );
}
