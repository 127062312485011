import { createGlobalStyle } from 'styled-components';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends StakingThemeType {}
}

export const GlobalStyle = createGlobalStyle(({ theme }) => ({
  body: {
    color: theme.text.primary,
    backgroundColor: theme.background.primary,
  },
}));

export const stakingTheme = {
  text: {
    primary: '#F2F2F4',
    secondary: '#979798',
    disabled: '#3e3e3e',
    link: '#0080FF',
    linkDisabled: '#6B89A5',
  },
  background: {
    primary: '#111111',
    secondary: '#262626',
  },
  border: {
    primary: '#F2F2F4',
    secondary: '#383839',
  },
  switch: {
    indicator: {
      background: '#F2F2F4',
    },
  },
  button: {
    primary: {
      active: {
        color: '#F2F2F4',
        background: 'transparent',
        border: '1px solid #F2F2F4',
      },
      hover: {
        color: '#262626',
        background: '#F2F2F4',
      },
      disabled: {
        color: '#737373',
        background: '#E5E5E7',
        border: 'none',
      },
    },
  },
  tooltip: {
    background: '#F2F2F4',
    text: '#111111',
    icon: {
      active: '#F2F2F4',
    },
  },
};

type StakingThemeType = typeof stakingTheme;
