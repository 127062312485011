import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValidatorGlobalInfo } from '../../api';
import { ClaimProof, KycStep, Transaction, ValidatorBalances, ValidatorStatus } from '../../types';

type EmailVerificationStep = 'email' | 'secret' | 'sign' | 'success' | 'error';
type AccessKeyStep = 'sign' | 'key' | 'error';

export interface ValidatorStakeInfo {
  currentPosition?: number;
  currentStake: string;
  totalInterest: string;
  validatorStatus: ValidatorStatus;
  validatorText: string;
  kycCompleted: boolean;
  whitelisted: boolean;
  emailVerified: boolean;
  claimProof?: ClaimProof;
}

export interface ValidatorState {
  globalInfo?: ValidatorGlobalInfo;
  userBalances?: ValidatorBalances;
  userStake?: ValidatorStakeInfo;
  approving?: boolean;
  transactions: Transaction[];
  emailVerificationModal?: {
    step: EmailVerificationStep;
    email?: string;
    secret?: string;
    nonce?: string;
    error?: string;
  };
  validatorKeyModal?: {
    step: AccessKeyStep;
    accessKey?: string;
    error?: string;
  };
  kycModal?: {
    step: KycStep;
    link?: string;
    error?: string;
  };
}

const initialState: ValidatorState = {
  transactions: [],
};

const slice = createSlice({
  name: 'validator',
  initialState,
  reducers: {
    setGlobalInfo: (state, { payload }: PayloadAction<{ globalInfo: ValidatorGlobalInfo }>) => {
      state.globalInfo = payload.globalInfo;
    },
    setUserStake: (state, { payload }: PayloadAction<{ userStake: ValidatorStakeInfo }>) => {
      state.userStake = payload.userStake;
    },
    setUserBalances: (state, { payload }: PayloadAction<{ userBalances: ValidatorBalances }>) => {
      state.userBalances = payload.userBalances;
    },
    setApproving: (state, { payload }: PayloadAction<{ approving: boolean }>) => {
      state.approving = payload.approving;
    },
    setTransactions: (state, { payload }: PayloadAction<{ transactions: Transaction[] }>) => {
      state.transactions = payload.transactions;
    },
    openEmailVerificationModal: (state) => {
      state.emailVerificationModal = {
        step: 'email',
      };
    },
    closeEmailVerificationModal: (state) => {
      state.emailVerificationModal = undefined;
    },
    setValidatorEmail: (state, { payload }: PayloadAction<{ email: string }>) => {
      if (state.emailVerificationModal) {
        state.emailVerificationModal.email = payload.email;
      }
    },
    setEmailVerificationSecret: (state, { payload }: PayloadAction<{ secret: string }>) => {
      if (state.emailVerificationModal) {
        state.emailVerificationModal.secret = payload.secret;
      }
    },
    setEmailVerificationStep: (state, { payload }: PayloadAction<{ step: EmailVerificationStep }>) => {
      if (state.emailVerificationModal) {
        state.emailVerificationModal.step = payload.step;
      }
    },
    setEmailVerificationNonce: (state, { payload }: PayloadAction<{ nonce: string }>) => {
      if (state.emailVerificationModal) {
        state.emailVerificationModal.nonce = payload.nonce;
      }
    },
    setEmailVerificationError: (state, { payload }: PayloadAction<{ error: string }>) => {
      if (state.emailVerificationModal) {
        state.emailVerificationModal.error = payload.error;
        state.emailVerificationModal.step = 'error';
      }
    },
    openValidatorKeyModal: (state) => {
      state.validatorKeyModal = {
        step: 'sign',
      };
    },
    closeValidatorKeyModal: (state) => {
      state.validatorKeyModal = undefined;
    },
    setValidatorKeyError: (state, { payload }: PayloadAction<{ error: string }>) => {
      if (state.validatorKeyModal) {
        state.validatorKeyModal.error = payload.error;
        state.validatorKeyModal.step = 'error';
      }
    },
    setValidatorAccessKey: (state, { payload }: PayloadAction<{ accessKey: string }>) => {
      if (state.validatorKeyModal) {
        state.validatorKeyModal.accessKey = payload.accessKey;
        state.validatorKeyModal.step = 'key';
      }
    },
    openKycModal: (state) => {
      state.kycModal = {
        step: 'validate-wallet',
      };
    },
    closeKycModal: (state) => {
      state.kycModal = undefined;
    },
    setKycModalStep: (state, { payload }: PayloadAction<{ step: KycStep }>) => {
      if (state.kycModal) {
        state.kycModal.step = payload.step;
      }
    },
    setKycLink: (state, { payload }: PayloadAction<{ link: string }>) => {
      if (state.kycModal) {
        state.kycModal.link = payload.link;
      }
    },
    setKycError: (state, { payload }: PayloadAction<{ error: string }>) => {
      if (state.kycModal) {
        state.kycModal.error = payload.error;
        state.kycModal.step = 'error';
      }
    },
    resetValidatorUserInfo: (state) => {
      return { ...initialState, globalInfo: state.globalInfo };
    },
  },
});

export const validatorState = (state: RootState) => state.validator;
export const validatorReducer = slice.reducer;

export const {
  setUserStake,
  setUserBalances,
  setApproving,
  setTransactions,
  setGlobalInfo,
  resetValidatorUserInfo,
  openEmailVerificationModal,
  closeEmailVerificationModal,
  setValidatorEmail,
  setEmailVerificationSecret,
  setEmailVerificationStep,
  setEmailVerificationNonce,
  setEmailVerificationError,
  openValidatorKeyModal,
  closeValidatorKeyModal,
  setValidatorKeyError,
  setValidatorAccessKey,
  openKycModal,
  closeKycModal,
  setKycModalStep,
  setKycLink,
  setKycError,
} = slice.actions;
export const approve = createAction(`${slice.name}/approve`);
export const stake = createAction<{ amount: string }>(`${slice.name}/stake`);
export const unstake = createAction<{ amount: string }>(`${slice.name}/unstake`);
export const claim = createAction(`${slice.name}/claim`);
export const kycValidate = createAction(`${slice.name}/kycValidate`);
