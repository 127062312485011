import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { mainState } from './slice';
import { Body } from './Body';
import { Header } from './Header';
import { TransactionStatusModal } from './TransactionStatusModal';

const MainWrapper = styled.div<{ $hideOverflow?: boolean }>(({ $hideOverflow }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: $hideOverflow ? 'hidden' : 'auto',
  height: $hideOverflow ? '100vh' : 'auto',
}));

export function Main() {
  const { connectModalVisible, burgerMenuVisible } = useSelector(mainState);

  return (
    <MainWrapper $hideOverflow={connectModalVisible || burgerMenuVisible}>
      <TransactionStatusModal />
      <Header />
      <Body />
    </MainWrapper>
  );
}
