import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ValidatorStatus } from '../../../types';
import { ValidatorStakeInfo, validatorState } from '../../validator/slice';
import { BalanceField } from '../common';
import {
  AccountDashboardProps,
  Circle,
  InfoBlock,
  Status,
  StatusDescription,
  StatusText,
  StatusWrapper,
} from './common';

const Seperator = styled.div({
  width: '1px',
  height: '34px',
  backgroundColor: '#B7B7CC',
});

export function ValidatorStatusBox({ styled }: AccountDashboardProps) {
  const { userBalances, userStake } = useSelector(validatorState);

  const validatorStatus = useMemo(() => getValidatorStatus(userStake), [userStake]);

  if (!userBalances) {
    return null;
  }

  return (
    <InfoBlock $styled={styled}>
      <h5>Validator status:</h5>
      <BalanceField>
        <StatusWrapper>
          <Status>
            <Circle $color={validatorStatus.color} />
            <StatusText>{validatorStatus.text}</StatusText>
          </Status>
          <Seperator />
          <StatusDescription>{validatorStatus.description}</StatusDescription>
        </StatusWrapper>
      </BalanceField>
    </InfoBlock>
  );
}

const statusInfo: { [key in ValidatorStatus]: { color: string; text: string } } = {
  NO_DATA: { color: '#ADB1D1', text: 'No data' },
  OK: { color: '#01994D', text: 'Running' },
  ERROR: { color: '#DF4848', text: 'Error' },
};

function getValidatorStatus(userStake?: ValidatorStakeInfo): { color: string; text: string; description: string } {
  const status: ValidatorStatus = userStake?.validatorStatus || 'NO_DATA';

  let description = userStake?.validatorText || '';
  if (!description && status === 'NO_DATA') {
    description = 'Node was never launched';
  }

  return {
    color: statusInfo[status].color,
    text: statusInfo[status].text,
    description,
  };
}
