import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeEmailVerificationModal, validatorState } from '../../../validator/slice';
import { Modal } from '../../Modal';
import { ErrorStep, MetamaskStep } from '../../wizard';
import { EmailStep } from './EmailStep';
import { SecretStep } from './SecretStep';
import { SuccessStep } from './SuccessStep';

export function EmailVerificationModal() {
  const { emailVerificationModal } = useSelector(validatorState);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeEmailVerificationModal());
  }, [dispatch]);

  if (!emailVerificationModal) {
    return null;
  }

  const { step } = emailVerificationModal;

  return (
    <Modal
      title="Verify your e-mail"
      description="To verify your wallet, please provide a valid e-mail address and confirm it."
      onClose={handleClose}
      maxWidth="698px"
    >
      {step === 'email' && <EmailStep />}
      {step === 'secret' && <SecretStep />}
      {step === 'sign' && <MetamaskStep />}
      {step === 'success' && <SuccessStep />}
      {step === 'error' && <ErrorStep text={emailVerificationModal.error} />}
    </Modal>
  );
}
