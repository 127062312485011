import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Transaction } from '../../../types';
import { SmallHeading } from '../common';
import { TransactionItem } from './TransactionItem';

const collapsedTransactionCount = 5;

const ActivitiesWrapper = styled.div({
  marginTop: '2rem',
  fontSize: '18px',
});

const ActivityExpander = styled.div(({ theme }) => ({
  fontSize: '18px',
  color: theme.text.primary,
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  borderTop: `1px solid ${theme.border.secondary}`,
  paddingTop: '1rem',
  marginTop: '0.25rem',
  '& img:nth-child(1)': {
    marginRight: '1rem',
    fill: 'red',
  },
  '& img:nth-child(2)': {
    marginLeft: '1rem',
  },
}));

const NoTransactions = styled.div({
  textAlign: 'center',
  margin: '8px 20px 1rem 20px',
});

interface TransactionListProps {
  transactions: Transaction[];
}

export function TransactionList({ transactions }: TransactionListProps) {
  const [transactionsExpanded, setTransactionsExpanded] = useState<boolean>(false);
  const visibleTransactions = transactionsExpanded ? transactions : transactions.slice(0, collapsedTransactionCount);

  const handleToggleActivitiesExpand = useCallback(() => {
    setTransactionsExpanded((expanded) => !expanded);
  }, []);

  return (
    <ActivitiesWrapper>
      {visibleTransactions.length === 0 && <NoTransactions>No activity yet.</NoTransactions>}
      {visibleTransactions.length > 0 && (
        <>
          <SmallHeading>Your activity</SmallHeading>
          {visibleTransactions.map((transaction) => (
            <TransactionItem key={transaction.hash} transaction={transaction} />
          ))}
        </>
      )}
      {transactionsExpanded && <div></div>}
      {transactions.length > collapsedTransactionCount && (
        <ActivityExpander onClick={handleToggleActivitiesExpand}>
          <img
            src="/images/full-log-icon.svg"
            loading="lazy"
            alt=""
            style={{
              transform: `rotate(${transactionsExpanded ? '180deg' : '0'})`,
              transition: 'transform 0.25s ease-in-out',
            }}
          />
          {transactionsExpanded ? 'Show Less activity' : 'Show full activity log'}
          <img
            src="/images/full-log-icon.svg"
            loading="lazy"
            alt=""
            style={{
              transform: `rotate(${transactionsExpanded ? '180deg' : '0'})`,
              transition: 'transform 0.25s ease-in-out',
            }}
          />
        </ActivityExpander>
      )}
    </ActivitiesWrapper>
  );
}
