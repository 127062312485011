import React from 'react';
import styled from 'styled-components';
import { responsive } from './theme';

const ErrorStepWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
});

const ErrorImage = styled.img({
  width: '30px',
  marginRight: '20px',
});

const ErrorLabel = styled.div({
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '3px',
  color: '#B3B3B3',
});

const ErrorText = styled.div({
  fontWeight: 500,
  fontSize: '21px',
  [responsive.sm]: {
    fontSize: '18px',
  },
});

interface ErrorStepProps {
  text?: string;
}

export function ErrorStep(props: ErrorStepProps) {
  const { text = 'Something went wrong.' } = props;

  return (
    <ErrorStepWrapper>
      <ErrorImage src="/images/error.svg" loading="lazy" alt="" />
      <div>
        <ErrorLabel>ERROR</ErrorLabel>
        <ErrorText>{text}</ErrorText>
      </div>
    </ErrorStepWrapper>
  );
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
});

const MetamaskLogoWrapper = styled.div({
  marginTop: '23px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'center',
});

const MetamaskLogo = styled.img({
  width: '45px',
});

const MetamaskText = styled.div({
  fontSize: '18px',
  lineHeight: '20px',
  textAlign: 'center',
  marginBottom: '23px',
});

export function MetamaskStep() {
  return (
    <Wrapper>
      <div>
        <MetamaskLogoWrapper>
          <MetamaskLogo src="/images/metamask-logo.svg" loading="lazy" alt="" />
        </MetamaskLogoWrapper>
        <MetamaskText>
          Request to sign a nonce is <br /> pending on MetaMask
        </MetamaskText>
      </div>
    </Wrapper>
  );
}
