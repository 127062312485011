import { call, fork, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
  createVerificationRequest,
  CreateVerificationRequestResponse,
  getEmailVerificationNonce,
  GetEmailVerificationNonceResponse,
  getGlobalValidatorInfo,
  getValidatorHistory,
  getValidatorUserStake,
  validateSignedRequest,
  ValidateSignedRequestResponse,
} from '../../api';
import { Transaction } from '../../types';
import { bigParse, calculateUnclaimedRewards, toFixed } from '../../utils/calculations';
import { formatTransactionDate } from '../../utils/date';
import { mapHistoryItem, mapValidatorStakeInfo } from '../../utils/map';
import {
  approveValidator,
  checkValidatorBalance,
  claimRewardFromValidator,
  ClaimRewardProps,
  depositToValidator,
  signWallet,
  TransactionWaitConfirmation,
  withdrawFromValidator,
} from '../../utils/metamask';
import { formatThousand } from '../../utils/numbers';
import { showError } from '../main/sagas';
import {
  mainState,
  resetUserInfo,
  setAccountTab,
  setTransactionModal,
  setUserWallet,
  TransactionModal,
} from '../main/slice';
import { getKycRoute } from '../router/routes';
import {
  approve,
  claim,
  kycValidate,
  openValidatorKeyModal,
  resetValidatorUserInfo,
  setApproving,
  setEmailVerificationError,
  setEmailVerificationNonce,
  setEmailVerificationSecret,
  setEmailVerificationStep,
  setGlobalInfo,
  setKycError,
  setKycLink,
  setKycModalStep,
  setTransactions,
  setUserBalances,
  setUserStake,
  setValidatorAccessKey,
  setValidatorEmail,
  setValidatorKeyError,
  stake,
  unstake,
  validatorState,
} from './slice';

function* initialize() {
  yield* fork(fetchGlobalInfo);
}

function* fetchGlobalInfo() {
  try {
    const globalInfo = yield* call(getGlobalValidatorInfo);
    yield* put(setGlobalInfo({ globalInfo: { ...globalInfo, annual_yield_current: 0 } }));
  } catch (err) {
    yield* call(showError, err);
  }
}

function* watchStake({ payload }: ReturnType<typeof stake>) {
  const amount = formatThousand(payload.amount);

  const modal: TransactionModal = {
    title: `Staking ${amount} NOIA`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const response = yield* call(depositToValidator, payload.amount);
    const newTransaction: Transaction = {
      amount: payload.amount,
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'stake',
      contractType: 'validator',
    };

    const { transactions } = yield* select(validatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Successfully staked ${amount} NOIA`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to stake ${amount} NOIA`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* watchUnstake({ payload }: ReturnType<typeof stake>) {
  const amount = formatThousand(payload.amount);

  const modal: TransactionModal = {
    title: `Unstaking ${amount} NOIA`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const response = yield* call(withdrawFromValidator, payload.amount);
    const newTransaction: Transaction = {
      amount: payload.amount,
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'unstake',
      contractType: 'validator',
    };

    const { transactions } = yield* select(validatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Successfully unstaked ${amount} NOIA`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to unstake ${amount} NOIA`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* watchClaim() {
  const { userWallet } = yield* select(mainState);
  const { userStake, userBalances } = yield* select(validatorState);

  if (!userStake || !userBalances || !userWallet) {
    return;
  }

  const { claimProof } = userStake;
  if (!claimProof) {
    return;
  }

  const amountBig = calculateUnclaimedRewards(claimProof.reward, userBalances.totalPayouts);
  const amount = toFixed(bigParse(amountBig));

  const modal: TransactionModal = {
    title: `Claiming ${amount} NOIA`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const request: ClaimRewardProps = {
      recipient: userWallet.address,
      totalEarned: claimProof.reward,
      blockNumber: claimProof.block,
      proof: claimProof.proof,
    };

    const response = yield* call(claimRewardFromValidator, request);
    const newTransaction: Transaction = {
      amount: amount,
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'claim',
      contractType: 'validator',
    };

    const { transactions } = yield* select(validatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Successfully claimed ${amount} NOIA`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to claim ${amount} NOIA`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* watchApprove() {
  const modal: TransactionModal = {
    title: `Approving validator wallet`,
    description: 'Communicating with Ethereum blockchain...',
    status: 'progress',
    locked: true,
  };

  yield* put(setTransactionModal({ modal }));
  try {
    const response = yield* call(approveValidator);
    const newTransaction: Transaction = {
      amount: '0',
      date: formatTransactionDate(new Date()),
      hash: response.hash,
      pending: true,
      action: 'approve',
      contractType: 'validator',
    };

    const { transactions } = yield* select(validatorState);
    const updatedTransactions = [newTransaction, ...transactions];
    yield* put(setTransactions({ transactions: updatedTransactions }));
    yield* put(setAccountTab({ tab: 'Activity' }));
    yield* put(setApproving({ approving: true }));
    yield* fork(waitForTransactionConfirmation, response.wait);

    const successModal: TransactionModal = {
      title: `Validator wallet successfully approved`,
      description: 'Transaction has been successful',
      status: 'success',
    };

    yield* put(setTransactionModal({ modal: successModal }));
  } catch (err: any) {
    const canceled = err.code === 4001;
    const failedModal: TransactionModal = {
      title: `Failed to approve validator wallet`,
      description: canceled ? 'Transaction canceled' : 'Transaction unsuccessful',
      status: 'error',
    };

    yield* put(setTransactionModal({ modal: failedModal }));

    if (!canceled) {
      yield* call(showError, err);
    }
  }
}

function* waitForTransactionConfirmation(wait: TransactionWaitConfirmation) {
  try {
    const { transactionHash } = yield* call(wait);
    const { userWallet } = yield* select(mainState);
    const { transactions } = yield* select(validatorState);
    const existingTransaction = transactions.find((x) => x.hash === transactionHash);
    if (existingTransaction) {
      const updatedTransactions = transactions.map((x) => (x.hash === transactionHash ? { ...x, pending: false } : x));
      yield* put(setTransactions({ transactions: updatedTransactions }));

      if (userWallet) {
        const userBalances = yield* call(checkValidatorBalance, userWallet.address);
        yield* put(setUserBalances({ userBalances }));

        if (existingTransaction.action === 'approve') {
          yield* put(setApproving({ approving: false }));
        }
      }
    }
  } catch (err: any) {
    if (err.reason && err.hash) {
      const { transactions } = yield* select(validatorState);
      const existingTransaction = transactions.find((x) => x.hash === err.hash);
      if (existingTransaction) {
        const updatedTransactions = transactions.map((x) =>
          x.hash === err.hash ? { ...x, failReason: err.reason, pending: undefined } : x,
        );
        yield* put(setTransactions({ transactions: updatedTransactions }));
      }
    } else {
      yield* call(showError, err);
    }
  }
}

function* watchSetUserWallet() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  yield* fork(fetchHistory);

  try {
    const userBalances = yield* call(checkValidatorBalance, userWallet.address);
    yield* put(setUserBalances({ userBalances }));
  } catch (err) {
    yield* call(showError, err);
  }

  yield* call(refreshUserStake);
}

function* refreshUserStake() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  try {
    const response = yield* call(getValidatorUserStake, userWallet.address);
    const userStake = mapValidatorStakeInfo(response);
    yield* put(setUserStake({ userStake }));
  } catch (err: any) {
    if (err.response.status !== 404) {
      yield* call(showError, err);
    }
  }
}

function* fetchHistory() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  try {
    const items = yield* call(getValidatorHistory, userWallet.address);
    const mapped: Transaction[] = items.map(mapHistoryItem);
    const { transactions } = yield* select(validatorState);
    const tempTransactions = transactions.filter((x) => !mapped.some((y) => y.hash === x.hash));
    const updated = [...tempTransactions, ...mapped];

    yield* put(setTransactions({ transactions: updated }));
  } catch (err: any) {
    if (err?.response?.status !== 404) {
      yield* call(showError, err);
    }
  }
}

function* watchResetUserInfo() {
  yield* put(resetValidatorUserInfo());
}

function* watchSetValidatorEmail() {
  const { emailVerificationModal: emailVerification } = yield* select(validatorState);
  if (!emailVerification) {
    return;
  }

  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  const { email } = emailVerification;
  try {
    yield* call(createVerificationRequest, {
      operationType: 'VALIDATOR_EMAIL_VERIFY',
      wallet: userWallet.address,
      email,
    });
  } catch (err) {
    yield* put(setEmailVerificationError({ error: 'Failed to send verification code to email. Try again.' }));
    return;
  }

  yield* put(setEmailVerificationStep({ step: 'secret' }));
}

function* watchSetEmailVerificationSecret() {
  const { emailVerificationModal: emailVerification } = yield* select(validatorState);
  if (!emailVerification) {
    return;
  }

  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  const { secret } = emailVerification;
  if (!secret) {
    return;
  }

  let nonceResponse: GetEmailVerificationNonceResponse;
  try {
    nonceResponse = yield* call(getEmailVerificationNonce, {
      wallet: userWallet.address,
      secret,
    });
  } catch (err) {
    yield* put(setEmailVerificationError({ error: 'Failed to validate secret that was sent to email. Try again.' }));
    return;
  }

  const { nonce, messageForVerification } = nonceResponse;

  yield* put(setEmailVerificationNonce({ nonce }));
  yield* put(setEmailVerificationStep({ step: 'sign' }));

  let signResult;
  try {
    signResult = yield* call(signWallet, messageForVerification, userWallet.address);
  } catch (err) {
    yield* put(setEmailVerificationError({ error: 'Failed to sign with MetaMask. Try again.' }));
    return;
  }

  let nonceSignResponse: ValidateSignedRequestResponse;
  try {
    nonceSignResponse = yield* call(validateSignedRequest, { nonce, hash: signResult });
    if (!nonceSignResponse.validated) {
      yield* put(setEmailVerificationError({ error: 'Signature is not valid. Try again.' }));
      return;
    }
  } catch (err) {
    yield* put(setEmailVerificationError({ error: 'Failed to validate signed request. Try again.' }));
    return;
  }

  yield* call(refreshUserStake);
  yield* put(setEmailVerificationStep({ step: 'success' }));
}

function* watchShowAccessKeyModal() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  let nonceResponse: CreateVerificationRequestResponse;
  try {
    nonceResponse = yield* call(createVerificationRequest, {
      operationType: 'VALIDATOR_GET_ACCESS_KEY',
      wallet: userWallet.address,
    });
  } catch (err) {
    yield* put(setValidatorKeyError({ error: 'Failed to create nonce for access key. Try again.' }));
    return;
  }

  const { messageForVerification, nonce } = nonceResponse;
  let signResult;
  try {
    signResult = yield* call(signWallet, messageForVerification, userWallet.address);
  } catch (err) {
    yield* put(setValidatorKeyError({ error: 'Failed to sign with MetaMask. Try again.' }));
    return;
  }

  let nonceSignResponse: ValidateSignedRequestResponse;
  try {
    nonceSignResponse = yield* call(validateSignedRequest, { nonce, hash: signResult });
    if (!nonceSignResponse.validated) {
      yield* put(setValidatorKeyError({ error: 'Signature is not valid. Try again.' }));
      return;
    }
  } catch (err) {
    yield* put(setValidatorKeyError({ error: 'Failed to validate signed request. Try again.' }));
    return;
  }

  yield* put(setValidatorAccessKey({ accessKey: nonceSignResponse.access_key || '' }));
}

function* watchKycValidate() {
  const { userWallet } = yield* select(mainState);
  if (!userWallet) {
    return;
  }

  let nonceResponse: CreateVerificationRequestResponse;

  try {
    nonceResponse = yield* call(createVerificationRequest, {
      operationType: 'VALIDATOR_KYC',
      wallet: userWallet.address,
    });
  } catch (err) {
    yield* put(setKycError({ error: 'Failed to fetch validation request. Try again.' }));
    return;
  }

  yield* put(setKycModalStep({ step: 'metamask-pending' }));

  let signResult;
  try {
    signResult = yield* call(signWallet, nonceResponse.messageForVerification, userWallet.address);
  } catch (err) {
    yield* put(setKycError({ error: 'Failed to sign with MetaMask. Try again.' }));
    return;
  }

  let kycResponse: ValidateSignedRequestResponse;
  try {
    kycResponse = yield* call(validateSignedRequest, { nonce: nonceResponse.nonce, hash: signResult });
    if (!kycResponse.validated) {
      yield* put(setKycError({ error: 'Signature is not valid. Try again.' }));
      return;
    }
  } catch (err) {
    yield* put(setKycError({ error: 'Failed to validate signed request. Try again.' }));
    return;
  }

  const link = location.origin + getKycRoute(kycResponse.kyc_token || '');
  yield* put(setKycLink({ link }));
  yield* put(setKycModalStep({ step: 'kyc-link' }));
}

export function* validatorSagas() {
  yield* takeEvery(stake, watchStake);
  yield* takeEvery(unstake, watchUnstake);
  yield* takeLatest(approve, watchApprove);
  yield* takeEvery(claim, watchClaim);
  yield* takeLatest(setUserWallet, watchSetUserWallet);
  yield* takeLatest(resetUserInfo, watchResetUserInfo);
  yield* takeLatest(setValidatorEmail, watchSetValidatorEmail);
  yield* takeLatest(setEmailVerificationSecret, watchSetEmailVerificationSecret);
  yield* takeLatest(openValidatorKeyModal, watchShowAccessKeyModal);
  yield* takeLatest(kycValidate, watchKycValidate);
  yield* initialize();
}
