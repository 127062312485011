import Big from 'big.js';
import { HistoryItem } from '../api';
import { UnstakeItem } from '../types';

export const noValue = '0';
const bigDecimals = new Big(1e18);
const bigZero = new Big(0);
export const validatorApproveAmount = new Big(1e6).mul(bigDecimals).toFixed();
export const nominatorApproveAmount = new Big(25e6).mul(bigDecimals).toFixed();

export function round(num?: number, precision = 0) {
  if (num === undefined) {
    return noValue;
  }

  return toFixed(num.toString(), precision);
}

export function calculateAvailableAmount({
  total,
  balance,
  limit,
}: {
  total: string;
  balance: string;
  limit: number;
}): string {
  const bigTotal = new Big(total);
  const bigLimit = new Big(limit).mul(bigDecimals);
  const bigBalance = new Big(balance);

  if (bigTotal.eq(bigZero)) {
    return '0';
  }

  if (bigBalance.gte(bigLimit)) {
    return '0';
  }

  const bigUnfilled = bigLimit.sub(bigBalance);

  if (bigTotal.gt(bigUnfilled)) {
    return bigUnfilled.div(bigDecimals).toFixed();
  }

  return bigTotal.div(bigDecimals).toFixed();
}

export function calculateAvailableUnstake(userValidatorBalance?: string): string {
  if (!userValidatorBalance || userValidatorBalance === '0') {
    return '0';
  }

  return bigParse(userValidatorBalance);
}

export function calculateUnclaimedRewards(totalRewards?: string, totalPayouts?: string) {
  if (!totalRewards) {
    return '0';
  }

  if (!totalPayouts) {
    return toFixed(totalRewards);
  }

  const rewardsBig = new Big(totalRewards);
  const payoutsBig = new Big(totalPayouts);
  if (payoutsBig.gte(rewardsBig)) {
    return '0';
  }

  return toFixed(rewardsBig.sub(payoutsBig));
}

export function getDecimalAount(amount: string) {
  return new Big(amount).mul(bigDecimals).toFixed();
}

export function bigParse(value: bigint | string | number, removeZeros = true): string {
  let text = value.toString();
  if (text.length > 0 && text[text.length - 1] === 'n') {
    text = text.slice(0, text.length - 1);
  }
  let big = new Big(text);
  if (removeZeros) {
    big = big.div(bigDecimals);
  }

  return big.toFixed();
}

(window as any).bigParse = bigParse;

export function toFixed(value: Big | string, precision = 3): string {
  const text = value.toString();
  return new Big(text).toFixed(precision, 0).replace(/\.0*$|(\.\d*[1-9])0+$/, '$1');
}

export function getHistoryItemAmount(item: HistoryItem): string {
  let amount = new Big(item.token_delta).div(bigDecimals);
  if (item.tx_type === 'deposit') {
    amount = amount.mul(-1);
  }

  return toFixed(amount);
}

export function bigSum(bigs: string[]): string {
  const resultBig = bigs.reduce((acc, cur) => {
    const big = new Big(cur);
    return big.add(acc);
  }, new Big(0));

  return toFixed(resultBig);
}

export function getTotalStaked(stake_rate_current?: number) {
  if (!stake_rate_current) {
    return noValue;
  }

  const millions = stake_rate_current * 10;
  return round(millions, 2);
}

export function getNominatorUnstakeTotal(items: UnstakeItem[]) {
  const enabledAmounts = items.map((x) => x.amount);
  return bigSum(enabledAmounts);
}

export function getNominatorEnabledUnstakeTotal(items: UnstakeItem[]) {
  const enabledAmounts = items.filter((x) => x.enabled).map((x) => x.amount);
  return bigSum(enabledAmounts);
}

export function getNominatorBondedUnstakeTotal(items: UnstakeItem[]) {
  const enabledAmounts = items.filter((x) => !x.enabled).map((x) => x.amount);
  return bigSum(enabledAmounts);
}

export function getValidatorPosition(position?: number): string {
  if (!position || position === -1) {
    return '-';
  }

  return position.toString();
}
