import styled from 'styled-components';

export const Content = styled.div({
  display: 'flex',
  marginBottom: '18px',
  flex: 1,
});

export const Label = styled.div({
  textTransform: 'uppercase',
  fontSize: '16px',
});

export const ValidationText = styled.div({
  fontSize: '16px',
  color: '#DF4848',
});

export const LabelRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const InputBlock = styled.div({
  flex: 1,
  marginRight: '16px',
});

export const Input = styled.input<{ $error?: boolean }>(({ $error }) => ({
  border: `0.5px solid ${$error ? '#DF4848' : '#D2D2E0'}`,
  color: $error ? '#DF4848' : '#161515',
  padding: '8px 16px',
  display: 'flex',
  marginTop: '10px',
  borderRadius: '6px',
  outline: 'none',
  flex: 1,
  fontSize: '18px',
  lineHeight: '21.17px',
}));

export const InputWrapper = styled.div({
  display: 'flex',
});

export const SubmitWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
});
