import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { CSSObject } from 'styled-components';
import { mainState, toggleBurgerMenu } from './slice';
import { responsive } from './theme';

const Burger = styled.div<{ $visible?: boolean }>(() => ({
  flexDirection: 'column',
  // display: $visible ? 'flex' : 'flex',
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  zIndex: 10,
  position: 'absolute',
  top: '3rem',
  right: '3rem',
  display: 'none',
  [responsive.md]: {
    display: 'flex',
  },
}));

const burgerBunProps: CSSObject = {
  width: '24px',
  height: '2px',
  borderRadius: '4px',
  transition: 'all 0.25s ease-in-out',
};

const BurgerBunFirst = styled.div<{ $rotated?: boolean }>(({ theme, $rotated }) => ({
  ...burgerBunProps,
  position: 'absolute',
  top: $rotated ? '12px' : '10px',
  transform: `rotate(${$rotated ? '45deg' : '0'})`,
  background: theme.border.primary,
}));

const BurgerBunLast = styled.div<{ $rotated?: boolean }>(({ theme, $rotated }) => ({
  ...burgerBunProps,
  position: 'absolute',
  top: $rotated ? '12px' : '16px',
  transform: `rotate(${$rotated ? '-45deg' : '0'})`,
  background: theme.border.primary,
}));

export function Header() {
  const { burgerMenuVisible } = useSelector(mainState);
  const dispatch = useDispatch();
  const handleToggleBurger = useCallback(() => {
    dispatch(toggleBurgerMenu());
  }, [dispatch]);

  return (
    <Burger onClick={handleToggleBurger} $visible={burgerMenuVisible}>
      <BurgerBunFirst $rotated={burgerMenuVisible} />
      <BurgerBunLast $rotated={burgerMenuVisible} />
    </Burger>
  );
}
