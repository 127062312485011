import React from 'react';
import styled from 'styled-components';
import { NumberText, ValueHeading } from './common';
import { breakpoints, responsive } from './theme';
import { TooltipIcon } from './TooltipIcon';

const TooltipIconMobile = styled(TooltipIcon)({
  margin: '0',
});

const TooltipContainerFirst = styled.div({});
const TooltipContainerLast = styled.div({});

const StatNumber = styled(NumberText)({
  display: 'flex',
  alignItems: 'baseline',
  '& > div': {
    fontSize: '24px',
    fontWeight: 400,
  },
});

const StatItem = styled.div({
  flexBasis: '33%',
  [`${TooltipContainerFirst}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    '& h5': {
      fontSize: '11px',
      lineHeight: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
      margin: '0',
    },
  },
  [`${TooltipContainerLast}`]: {
    display: 'none',
  },
  [responsive.sm]: {
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    [`${StatNumber}`]: {
      color: '#595966',
      marginRight: '0.5rem',
    },
    [`${TooltipContainerFirst}`]: {
      marginRight: '1rem',
      marginBottom: 0,
      '& > div': {
        display: 'none',
      },
    },
    [`${TooltipContainerLast}`]: {
      display: 'flex',
    },
  },
});

const StakeStatsWrapper = styled.div({
  borderTop: '1px solid #D2D2E0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '1.5rem 0 0 0',
  maxWidth: breakpoints.sm,
  margin: 'auto auto 0 auto',
  width: '100%',
  [`${StatItem}:not(:last-child)`]: {
    marginRight: '2rem',
  },
  [responsive.sm]: {
    flexDirection: 'column',
    alignItems: 'center',
    [`${StatItem}:not(:last-child)`]: {
      marginRight: '0',
      marginBottom: '1rem',
    },
  },
});

interface StakeTabStatsProps {
  items: StakeItem[];
}

interface StakeItem {
  label: string;
  number: number | string;
  value: string;
  tooltip?: string;
}

export function StakeTabStats({ items }: StakeTabStatsProps) {
  return (
    <StakeStatsWrapper>
      {items.map((item, index) => (
        <StatItem key={index}>
          <TooltipContainerFirst>
            {item.tooltip && <TooltipIcon alignLeft variant="noMargin" text={item.tooltip} />}
            <h5>{item.label}</h5>
          </TooltipContainerFirst>
          <StatNumber>
            <div>{item.number}</div>
            <ValueHeading>{item.value}</ValueHeading>
          </StatNumber>
          <TooltipContainerLast>
            {item.tooltip && <TooltipIconMobile variant="noMargin" text={item.tooltip} />}
          </TooltipContainerLast>
        </StatItem>
      ))}
    </StakeStatsWrapper>
  );
}
