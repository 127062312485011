import * as dateUtils from './date';

export const second = 1000;
export const minute = second * 60;
export const hour = minute * 60;
export const day = hour * 24;
export const unstakeTimeLimit = 7 * day;

export function zeroPrefix(value: number): string {
  return `0${value}`.slice(-2);
}

export function formatTransactionDate(date: Date) {
  return `${date.getFullYear()}.${zeroPrefix(date.getMonth() + 1)}.${zeroPrefix(date.getDate())}`;
}

export function formatUnstakeDate(dateIso: string) {
  const date = new Date(dateIso);
  return `${date.getFullYear()} – ${zeroPrefix(date.getMonth() + 1)} - ${zeroPrefix(date.getDate())}`;
}

export function getTimeLeft(dateIso: string, currentDateIso: string): string | undefined {
  const date = new Date(dateIso);
  const current = new Date(currentDateIso);
  const diff = date.getTime() - current.getTime();
  if (diff <= 0) {
    return undefined;
  }

  if (diff <= hour) {
    if (diff <= minute) {
      return '1 Minute';
    }

    const minutes = Math.floor(diff / minute);
    let minText = `${minutes} Minute`;
    if (minutes !== 1) {
      minText += 's';
    }
    return minText;
  }

  if (diff <= day) {
    const hours = Math.floor(diff / hour);
    const minutes = Math.floor((diff - hours * hour) / minute);
    let hoursText = `${hours} Hour`;
    if (hours > 1) {
      hoursText += 's';
    }

    if (minutes === 0) {
      return hoursText;
    }

    let result = `${hoursText} ${minutes} Minute`;
    if (minutes > 1) {
      result += 's';
    }

    return result;
  }

  const days = Math.floor(diff / day);
  const hours = Math.floor((diff - days * day) / hour);
  let daysText = `${days} Day`;
  if (days > 1) {
    daysText += 's';
  }

  if (hours === 0) {
    return daysText;
  }

  const result = `${daysText} ${hours} Hour`;
  if (hours > 1) {
    return `${result}s`;
  }

  return result;
}

export function getUnstakeTimeLeft(isoDate: string): { enabled: boolean; text: string } {
  const timeLeft = dateUtils.getTimeLeft(isoDate, new Date().toISOString());
  if (timeLeft === undefined) {
    return { enabled: true, text: 'Ready to Unstake' };
  }

  return { enabled: false, text: `Ready in ${timeLeft}` };
}

export function fromUnixDate(timestamp: number) {
  return new Date(timestamp * 1000);
}
