import { Tooltip, withStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../assets/img/copy-icon.svg';
import { responsive } from './theme';

interface CopyAddressProps {
  text: string;
  small?: boolean;
  tooltipPos?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const StyledImage = styled(CopyIcon)<{ $small?: boolean }>(({ $small }) => ({
  cursor: 'pointer',
  marginLeft: '0.5rem',
  flexShrink: 0,
  '& path': {
    transition: 'all 0.25s ease-in-out',
  },
  '&:hover path': {
    fill: '#F2F2F4',
  },
  width: $small ? '16px' : 'initial',
  height: $small ? '16px' : 'initial',
  [responsive.md]: {
    width: '16px',
    height: '16px',
  },
}));

export const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#F2F2F4',
    color: '#111111',
    boxShadow: 'none',
    fontSize: 11,
  },
  arrow: {
    color: '#F2F2F4',
  },
}))(Tooltip);

export const CopyAddress = ({ text, tooltipPos, small }: CopyAddressProps) => {
  const [copied, setCopied] = useState<boolean>(false);
  const timeout = useRef<NodeJS.Timeout>();
  const handleCopyText = useCallback((text, result) => {
    if (text && result) {
      setCopied(true);
    }
  }, []);

  useEffect(() => {
    if (copied) {
      timeout.current = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [copied]);

  return (
    <CustomTooltip title="Copied" arrow open={copied} placement={tooltipPos || 'top'}>
      <CopyToClipboard text={text} onCopy={handleCopyText}>
        <StyledImage $small={small} />
      </CopyToClipboard>
    </CustomTooltip>
  );
};
