import React from 'react';
import styled from 'styled-components';
import { BalanceField, NumberText, ValueHeading } from '../common';
import { responsive } from '../theme';

export const BalanceWrapper = styled.div<{ $styled?: boolean }>(({ $styled }) => {
  const styledFields = $styled
    ? {
        [`${BalanceField}`]: {
          minHeight: '85px',
          alignItems: 'center',
          [`${ValueHeading}`]: {
            height: '22px',
            alignSelf: 'initial',
          },
          [`${NumberText}`]: {
            fontSize: '36px',
            [responsive.sm]: {
              fontSize: '36px',
            },
          },
        },
      }
    : {};

  return {
    marginTop: '25px',
    '& h5': {
      fontSize: '18px',
      textTransform: $styled ? 'initial' : 'uppercase',
      fontWeight: 400,
      color: $styled ? '#595966' : 'inherit',
    },
    ...styledFields,
  };
});

export const Circle = styled.div<{ $color: string }>(({ $color }) => ({
  borderRadius: '50%',
  background: $color,
  minWidth: '7px',
  height: '7px',
  margin: '0 10px',
}));

export const StatusWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  height: '43px',
  flex: 1,
});

export const StatusText = styled.div({
  fontWeight: 600,
  fontSize: '18px',
  textTransform: 'uppercase',
  letterSpacing: '0.25em',
  flexGrow: 1,
});

export const StatusDescription = styled.div({
  color: '#9C9CB7',
  fontSize: '15px',
  flex: 1,
  textAlign: 'center',
  marginLeft: '5px',
});

export const Status = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
});

export const InfoBlock = styled.div<{ $styled?: boolean }>(({ $styled }) => ({
  marginBottom: $styled ? '25px' : '40px',
}));

export interface AccountDashboardProps {
  styled?: boolean;
}

interface DashboardItemProps {
  title: string;
  amount: string;
  styled?: boolean;
}

export function DashboardItem({ title, amount, styled }: DashboardItemProps) {
  return (
    <InfoBlock $styled={styled}>
      <h5>{title}</h5>
      <BalanceField>
        <NumberText>{amount}</NumberText>
        <ValueHeading>NOIA</ValueHeading>
      </BalanceField>
    </InfoBlock>
  );
}
