import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Account } from './Account';
import { ConnectWalletDesktop } from './ConnectWalletDesktop';
import { LogoImage, WidthWrapper } from './common';
import { Footer } from './Footer';
import { Page } from './Page';
import { mainState } from './slice';
import { breakpoints, responsive } from './theme';

const BurgerContentWrapper = styled.div<{ $open?: boolean }>(({ theme, $open }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'none',
  background: theme.background.secondary,
  zIndex: 9,
  overflowX: 'hidden',
  flexDirection: 'column',
  alignItems: 'center',
  [responsive.md]: {
    display: $open ? 'flex' : 'none',
  },
}));

const BurgerContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '3rem 0 0rem 0',
  margin: '0 auto',
  maxWidth: breakpoints.lg,
  height: '100%',
});

export function Body() {
  const { burgerMenuVisible } = useSelector(mainState);

  return (
    <>
      <ConnectWalletDesktop />
      <BurgerContentWrapper $open={burgerMenuVisible}>
        <WidthWrapper $fullHeight>
          <BurgerContent>
            <LogoImage src="/images/syntropy-logo-white.svg" loading="lazy" alt="" />
            <Account styled />
            <Footer greyed />
          </BurgerContent>
        </WidthWrapper>
      </BurgerContentWrapper>
      <Page />
      <Footer />
    </>
  );
}
