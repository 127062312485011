import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { mainReducer, MainState } from '../features/main/slice';
import { nominatorReducer, NominatorState } from '../features/nominator/slice';
import { validatorReducer, ValidatorState } from '../features/validator/slice';
import { getEnvironment } from '../utils/env';
import { rootSaga } from './sagas';
import { routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { createRouterReducer } from '../features/router/slice';

export const history = createBrowserHistory();

declare global {
  interface RootState {
    router: RouterState;
    main: MainState;
    nominator: NominatorState;
    validator: ValidatorState;
  }
}

const rootReducer = (history: History) => ({
  router: createRouterReducer(history),
  main: mainReducer,
  nominator: nominatorReducer,
  validator: validatorReducer,
});

const sagaMiddleware = createSagaMiddleware();

const createStore = () =>
  configureStore({
    reducer: rootReducer(history),
    devTools: getEnvironment() !== 'prod',
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: true,
        immutableCheck: true,
      }).concat(sagaMiddleware, routerMiddleware(history)),
  });

export const store = createStore();

export function initStore() {
  sagaMiddleware.run(rootSaga);
}
