import React from 'react';
import { useSelector } from 'react-redux';
import { nominatorState } from '../../nominator/slice';
import { useMainParams } from '../../router/routes';
import { validatorState } from '../../validator/slice';
import { TransactionList } from './TransactionList';

export function Transactions() {
  const { scope } = useMainParams();
  const { transactions: validatorTransctions } = useSelector(validatorState);
  const { transactions: nominatorTransactions } = useSelector(nominatorState);
  const transactions = scope === 'validator' ? validatorTransctions : nominatorTransactions;

  return <TransactionList transactions={transactions} />;
}
