import React from 'react';
import { useMainParams } from '../../router/routes';
import { AccountDashboardProps } from './common';
import { NominatorDashboard } from './NominatorDashboard';
import { ValidatorDashboard } from './ValidatorDashboard';

export function AccountDashboard({ styled }: AccountDashboardProps) {
  const { scope } = useMainParams();

  if (scope === 'validator') {
    return <ValidatorDashboard styled={styled} />;
  }

  return <NominatorDashboard styled={styled} />;
}
