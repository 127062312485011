import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { closeValidatorKeyModal, validatorState } from '../../validator/slice';
import { CopyAddress } from '../CopyAddress';
import { Modal } from '../Modal';
import { ErrorStep, MetamaskStep } from '../wizard';

const KeyWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `0.5px solid ${theme.border.primary}`,
  borderRadius: '10px',
}));

const CopyWrapper = styled.div({
  padding: '11px 24px',
});

const AccessKeyText = styled.div(({ theme }) => ({
  fontSize: '18px',
  paddingLeft: '35px',
  color: theme.text.primary,
}));

export function ValidatorKeyModal() {
  const { validatorKeyModal } = useSelector(validatorState);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeValidatorKeyModal());
  }, [dispatch]);

  if (!validatorKeyModal) {
    return null;
  }

  const { step, accessKey, error } = validatorKeyModal;

  return (
    <Modal
      title="Validator Access Key"
      description="To reveal validator access key you need to sign nonce with your wallet."
      onClose={handleClose}
      maxWidth="753px"
    >
      {step === 'sign' && <MetamaskStep />}
      {step === 'key' && <KeyStep accessKey={accessKey || ''} />}
      {step === 'error' && <ErrorStep text={error} />}
    </Modal>
  );
}

interface KeyStepProps {
  accessKey: string;
}

function KeyStep(props: KeyStepProps) {
  const { accessKey } = props;

  return (
    <KeyWrapper>
      <AccessKeyText>{accessKey}</AccessKeyText>
      <CopyWrapper>
        <CopyAddress small text={accessKey} />
      </CopyWrapper>
    </KeyWrapper>
  );
}
