import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculateAvailableUnstake, getValidatorPosition, toFixed } from '../../utils/calculations';
import { formatNumber, formatThousand } from '../../utils/numbers';
import { validateAvailableAmount } from '../../utils/validation';
import {
  AvailableStakeText,
  LargeHeading,
  StakeAmount,
  StakeAmountLabel,
  StakeError,
  StakeInput,
  StakeLabel,
  StakingTab,
  StakingWindow,
  StyledButton,
  ValueHeading,
} from '../main/common';
import { UnstakeImage } from '../main/MainTabs/common';
import { StakeTabStats } from '../main/StakeTabStats';
import { TooltipIcon } from '../main/TooltipIcon';
import { unstake, validatorState } from './slice';

export function UnstakeTab() {
  const { globalInfo, userBalances, userStake, transactions } = useSelector(validatorState);
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');

  const unstakeTransactionInProgress = useMemo(
    () => !!transactions.find((x) => x.action === 'unstake' && x.pending),
    [transactions],
  );

  const availableUnstake = useMemo(() => {
    return calculateAvailableUnstake(userBalances?.userValidatorBalance);
  }, [userBalances?.userValidatorBalance]);

  const error = useMemo(() => {
    return validateAvailableAmount(amount, availableUnstake);
  }, [amount, availableUnstake]);

  const unstakeEnabled = !error && amount.length !== 0 && !unstakeTransactionInProgress;

  const handleUnstake = useCallback(() => {
    if (!unstakeEnabled) {
      return;
    }

    dispatch(unstake({ amount }));
    setAmount('');
  }, [amount, unstakeEnabled, dispatch]);

  const handleChangeAmount = useCallback((evt) => {
    const val = evt.target.value;
    setAmount(val);
  }, []);

  const handleAmountClick = useCallback(() => {
    if (availableUnstake === '0') {
      return;
    }

    handleChangeAmount({ target: { value: availableUnstake } });
  }, [availableUnstake, handleChangeAmount]);

  return (
    <StakingTab>
      <StakingWindow>
        <TooltipIcon
          variant="absolute"
          text="You can easily unstake what you have previously staked. Currently, no bonding period is applied therefore the action is near instant. By removing your stake, you stand to give away your position in the validator list to the next one in line. By removing your stake, you do not affect the amount you had already accumulated."
        />
        <UnstakeImage />
        <LargeHeading>Unstake NOIA</LargeHeading>
        <StakeInput $error={!!error}>
          <StakeLabel>
            <StakeAmountLabel>Amount:</StakeAmountLabel>
            {error && <StakeError>{error}</StakeError>}
          </StakeLabel>
          <input type="text" placeholder="0.0" value={amount} onChange={handleChangeAmount} />
          <ValueHeading>NOIA</ValueHeading>
        </StakeInput>
        <AvailableStakeText>
          Available to unstake:
          <StakeAmount onClick={handleAmountClick}>
            {formatNumber(toFixed(availableUnstake, 18), formatThousand)} NOIA
          </StakeAmount>
        </AvailableStakeText>
        <StyledButton $disabled={!unstakeEnabled} onClick={handleUnstake}>
          {unstakeTransactionInProgress ? 'Unstaking...' : 'Unstake'}
        </StyledButton>
      </StakingWindow>
      <StakeTabStats
        items={[
          {
            label: 'Your current stake:',
            number: formatNumber(userStake?.currentStake, formatThousand),
            value: 'NOIA',
          },
          {
            label: 'Validator stake limit:',
            number: formatNumber(globalInfo?.stake_size_limit_max, formatThousand),
            value: 'NOIA',
            tooltip:
              'This is the maximum amount of tokens per one Validator. You can stake more if you wish but your reward will not be affected.',
          },
          {
            label: 'Validator position:',
            number: `${getValidatorPosition(userStake?.currentPosition)} / ${formatNumber(
              globalInfo?.validators_limit,
            )}`,
            value: '',
            tooltip:
              'You are actively being rewarded as long as your spot is 500 or lower. If you are outside the maximum number of validators, your rewards are paused until you move up the list. By standing in line (outside the 500) you reserve priority right to claim the next available spot as soon as one opens up.',
          },
        ]}
      />
    </StakingTab>
  );
}
